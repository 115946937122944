import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services";
import apiendpoints from "../../const/apiendpoints";
import { AddUserProps } from "./types";

export const userLogin = createAsyncThunk(
  "user/Login",
  async (requestPayload: {
    password: string;
    email_id: string;
  }): Promise<any> => {
    const response = await api.post(apiendpoints.login, requestPayload);
    if (response?.data?.success) {
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("message", JSON.stringify(response?.data?.message));
    }

    return response?.data;
  }
);

export const registerUsers = createAsyncThunk(
  "user/registerUser",
  async (requestPayload: AddUserProps): Promise<any> => {
    const resposne = await api.post(apiendpoints.createUsers, requestPayload);
    return resposne?.data;
  }
);

export const createOrderId = createAsyncThunk(
  "order/createRazorOrderId",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      apiendpoints.createRazorOrderID,
      requestPayload
    );
    return response?.data?.message;
  }
);

export const createUserOrder = createAsyncThunk(
  "order/createOrder",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(apiendpoints.createOrder, requestPayload);
    return response?.data?.message;
  }
);

export const createItems = createAsyncThunk(
  "order/createOrder",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(apiendpoints.createItems, requestPayload);
    return response?.data?.message;
  }
);
