import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiSuccessResponse, initialState, GetOrderResposne } from "./types";
import { registerUsers, createOrderId, userLogin } from "./api";

export const usersSlices = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [registerUsers.pending.toString()]: (state) => {
      state.isLoading = true;
      state.isRejected = false;
    },
    [registerUsers.fulfilled.toString()]: (
      state,
      action: PayloadAction<ApiSuccessResponse>
    ) => {
      state.isLoading = false;
      state.addUsersResposne = action.payload;
      state.isRejected = false;
    },
    [registerUsers.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.isRejected = true;
    },
    [createOrderId.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [createOrderId.fulfilled.toString()]: (
      state,
      action: PayloadAction<GetOrderResposne>
    ) => {
      state.isLoading = false;
      state.getOrderId = action.payload;
    },
    [createOrderId.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [userLogin.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [userLogin.fulfilled.toString()]: (
      state,
      action: PayloadAction<ApiSuccessResponse>
    ) => {
      state.isLoading = false;
      state.loginResposne = action.payload;
    },
    [userLogin.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default usersSlices.reducer;
