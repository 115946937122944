const apiendpoints = {
  login: "/login",
  createUsers: "/register_user",
  getCategories: "/get_categories",
  getSubCategories: "/get_subCategories",
  getSubCategoriesbyId: "/get_SubCategor_by_id",
  getProductsListByIds: "/get_product_by_ids",
  createRazorOrderID: "/create_razor_order",
  createOrder: "/create_order",
  createItems: "/create_items",
  getAllProduct: "/get_productlist",
  getCarsoulImage: "/get_carsoul_image",
  getQuotess: "/get_quotes",
};

export default apiendpoints;
