import { ReactElement, useEffect, useState } from "react";
import {
  Pagewrapper,
  SectionContainer,
  SectionWrapper,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import {
  CardContainer,
  SlideSection,
  CarouselImages,
  LoadMore,
} from "./subcomponents";
import { ProductCard } from "./ProductDetails/helpers";
import { Footer, Loader } from "../../components";
import { useNavigate } from "react-router";
import ROUTES from "../../const/routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAllProductLists,
  getCarsoulImage,
} from "../../redux/categories/api";
import { AppDispatch, RootState } from "../../redux/store";
import { addtoCart } from "../../redux/product/actions";
import Drawer from "../../components/Drawer";
import Login from "../../container/Login";
import { BASE_URL } from "../../services";
import { GetProductListResposne } from "../../redux/categories/types";

const Home = (): ReactElement => {
  const {
    category: { getProductsListsbyId, isProductLoading, getCarsoulImages },
  } = useSelector(
    (state: RootState) => ({ category: state.category }),
    shallowEqual
  );
  const navigate = useNavigate();
  const [loginDrawer, setLoginDrawer] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductLists());
    dispatch(getCarsoulImage());
  }, [dispatch]);

  const PAGE_SIZE = 4;

  const [index, setIndex] = useState(0);

  const [productDetails, setProductDetails] = useState<
    Array<GetProductListResposne>
  >([]);

  useEffect(() => {
    const numberOfItems = PAGE_SIZE * (index + 1);

    const newArray: any = [];

    for (let i = 0; i < getProductsListsbyId.length; i++) {
      if (i < numberOfItems) newArray.push(getProductsListsbyId[i]);
    }

    setProductDetails(newArray);
  }, [getProductsListsbyId, index]);

  return (
    <Pagewrapper>
      <Drawer
        show={loginDrawer}
        onClose={() => {
          setLoginDrawer(false);
        }}
        onOpen={() => {
          setLoginDrawer(false);
        }}
        component={
          <Login
            onClose={() => {
              setLoginDrawer(false);
            }}
          />
        }
      />
      <SlideSection pause="hover" fade>
        {getCarsoulImages?.map((img) => {
          return (
            <SlideSection.Item interval={img?.ti ? Number(img?.ti) : 4000}>
              <CarouselImages
                src={`${BASE_URL}carsoul/${img?.image}`}
                className="d-block w-100"
              />
            </SlideSection.Item>
          );
        })}
      </SlideSection>
      <SectionContainer>
        <SectionWrapper width="100%">
          {" "}
          <TitleWrapper margin="0 0 25px 0">
            <Title
              font="20px"
              lineHeight="30px"
              letterSpacing="0.5px"
              fontWeight={700}
            >
              OUR PRODUCTS
            </Title>
          </TitleWrapper>
          {isProductLoading ? (
            <Loader />
          ) : (
            <>
              <CardContainer>
                {productDetails?.length ? (
                  <>
                    {productDetails?.map((product, i) => {
                      return (
                        <ProductCard
                          key={i}
                          title={product?.product_name}
                          buttonLoader={false}
                          product={product}
                          onProductClick={() => {
                            navigate(ROUTES.PRODUCT_DETAILS, {
                              state: { selectedProduct: product },
                            });
                          }}
                          onClick={() => {
                            if (localStorage.getItem("isLoggedIn") === "true") {
                              dispatch(
                                addtoCart({
                                  ...product,
                                  without_discount: product?.price,
                                  discount_price:
                                    (Number(product?.price) *
                                      Number(`${product?.discount}`)) /
                                    100,
                                  price:
                                    Number(product?.price) -
                                    (Number(product?.price) *
                                      Number(`${product?.discount}`)) /
                                      100,
                                  quantity: 1,
                                })
                              );
                              navigate(ROUTES.CART_LIST, {
                                state: { selectedProduct: product },
                              });
                            } else {
                              setLoginDrawer(true);
                            }
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  <TitleWrapper>
                    <Title color="grey" font="16px">
                      No Products available
                    </Title>
                  </TitleWrapper>
                )}
              </CardContainer>
              {getProductsListsbyId?.length > 4 &&
                getProductsListsbyId?.length !== productDetails?.length && (
                  <>
                    <LoadMore onClick={() => setIndex(index + 1)}>
                      Loadmore....
                    </LoadMore>
                  </>
                )}
            </>
          )}
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default Home;
