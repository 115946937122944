export interface Values {
  name: string;
  phone_number: string;
  email: string;
  state: string;
  city: string;
  address: string;
  pin: string;
}

export const shippingDetailsValues: Values = {
  name: "",
  phone_number: "",
  email: "",
  state: "",
  city: "",
  address: "",
  pin: "",
};
