import { Button } from "react-bootstrap";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  background-color: ${({ backGround }) =>
    backGround ? backGround : "#223553"};
  color: white;
  border: none;
  align-items: center;
  border-radius: 0;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.5;
  overflow: hidden;
  padding: 12.5px 20px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.2s;
  z-index: 1;
  width: ${({ width }) => (width ? width : "auto")};
  i {
    padding: 0 10px 0 0;
    font-size: 15px;
    line-height: 1;
  }
  &:hover,
  &:active&:focus {
    background: ${({ hoverBackground }) =>
      hoverBackground ? hoverBackground : "#f78f14"};
  }
  &:active {
    transform: scale(0.9);
  }
`;
