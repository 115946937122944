import React, { ChangeEvent, ReactElement, useState, useEffect } from "react";
import {
  LoginContainer,
  LoginHeader,
  LoginWrapper,
  HeaderTitle,
  FormInput,
  LoginQuotes,
} from "./subcomponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DropdownWrapper,
  Loader,
  StyledButton,
  ToastMessage,
} from "../../components";
import { Title, TitleWrapper } from "../../pages/styledcomponents";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { AddUserProps } from "../../redux/user/types";
import Dropdown from "../../components/Dropdown";
import { State } from "country-state-city";
import { DropdownListPropsMultiSelect } from "../../components/Dropdown/typings";
import { registerUsers, userLogin } from "../../redux/user/api";
import Aos from "aos";

interface LoginProsp {
  onClose: () => void;
}

const Login = ({ onClose }: LoginProsp): ReactElement => {
  const {
    user: { addUser, addUsersResposne, isRejected, isLoading, loginResposne },
    category: { getQuotesList },
  } = useSelector(
    (state: RootState) => ({
      user: state.user,
      category: state.category,
    }),
    shallowEqual
  );

  const dispatch: AppDispatch = useDispatch();

  const [isLogin, setIsLogin] = useState(true);
  const [toast, setToast] = useState(true);
  const [registerValues, setRegisterValues] = useState(addUser as AddUserProps);
  const [loginValues, setLoginValues] = useState({
    email_id: "",
    password: "",
  });
  let stateLists: Array<DropdownListPropsMultiSelect> = [];
  State.getStatesOfCountry("IN")?.forEach((state) => {
    stateLists?.push({
      id: state.isoCode,
      name: state.name,
    });
  });

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);

  const isRegisterSuccess = toast && addUsersResposne?.status_code === 200;
  const isRegisterRejected = toast && isRejected;

  useEffect(() => {
    if (addUsersResposne?.status_code === 200) {
      setRegisterValues(addUser as AddUserProps);
    }
  }, [addUser, addUsersResposne, onClose]);

  const isUserFailed = toast && loginResposne?.success === false;
  const isUserSuccess = toast && loginResposne?.success === true;

  return (
    <LoginWrapper>
      {isLogin ? (
        <>
          <ToastMessage
            show={isUserFailed || isUserSuccess}
            handleClose={() => {
              setToast(false);
            }}
            severity={isUserFailed ? "error" : "success"}
            message={
              isUserFailed ? loginResposne?.message : "Login Successfully"
            }
          />
          <LoginHeader>
            <HeaderTitle>LOGIN</HeaderTitle>
            <FontAwesomeIcon
              icon={["fas", "xmark"]}
              className="x-icon"
              onClick={onClose}
              title="Close"
            />
          </LoginHeader>
          <LoginContainer>
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Email *"
                values={loginValues?.email_id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLoginValues({
                    ...loginValues,
                    email_id: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Password *"
                values={loginValues?.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLoginValues({
                    ...loginValues,
                    password: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <StyledButton
                width="100%"
                onClick={() => {
                  setToast(true);
                  dispatch(
                    userLogin({
                      ...loginValues,
                    })
                  );
                }}
              >
                {isLoading ? (
                  <Loader buttonLoader text={"Fetching user Details"} />
                ) : (
                  "Sign In"
                )}
              </StyledButton>
            </DropdownWrapper>
            <TitleWrapper textAlign="start" alignItems="start" paddingLeft="0">
              <Title
                font="15px"
                textAlign="start"
                color="#999999"
                hoverColor="#999999"
              >
                New Customer?{" "}
                <span
                  className="login-link"
                  onClick={() => {
                    setIsLogin(false);
                  }}
                >
                  Create your Account
                </span>
              </Title>
            </TitleWrapper>
            <LoginQuotes>{[...getQuotesList]?.reverse()?.[0]?.q2}</LoginQuotes>
          </LoginContainer>
        </>
      ) : (
        <>
          <ToastMessage
            show={
              (toast && isRejected) ||
              (toast && addUsersResposne?.status_code === 200)
            }
            handleClose={() => {
              setToast(false);
            }}
            severity={
              isRegisterRejected
                ? "error"
                : isRegisterSuccess
                ? "success"
                : "info"
            }
            message={
              isRegisterRejected
                ? "Try agin"
                : isRegisterSuccess
                ? "Added Successfully"
                : ""
            }
          />
          <LoginHeader>
            <HeaderTitle>REGISTER</HeaderTitle>
            <FontAwesomeIcon
              icon={["fas", "xmark"]}
              className="x-icon"
              onClick={onClose}
              title="Close"
            />
          </LoginHeader>
          <LoginContainer>
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Firstname *"
                required
                value={registerValues?.first_name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRegisterValues({
                    ...registerValues,
                    first_name: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Lastname"
                required
                value={registerValues?.last_name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRegisterValues({
                    ...registerValues,
                    last_name: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Email"
                required
                value={registerValues?.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRegisterValues({
                    ...registerValues,
                    email: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>{" "}
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Password"
                required
                value={registerValues?.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRegisterValues({
                    ...registerValues,
                    password: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <FormInput
                placeholder="Confirm Password"
                required
                value={registerValues?.confirm_password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRegisterValues({
                    ...registerValues,
                    confirm_password: e?.target.value,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <Dropdown
                dropdownList={stateLists}
                singleSelect
                placeholder={"State"}
                handleSelect={(item: Array<DropdownListPropsMultiSelect>) => {
                  setRegisterValues({
                    ...registerValues,
                    state: item[0]?.name,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper width="90%">
              <StyledButton
                width="100%"
                onClick={() => {
                  setToast(true);
                  dispatch(
                    registerUsers({
                      ...registerValues,
                      status: 1,
                    })
                  );
                }}
              >
                {isLoading ? (
                  <Loader buttonLoader text={"Please wait"} />
                ) : (
                  "Register"
                )}
              </StyledButton>
            </DropdownWrapper>
            <TitleWrapper textAlign="start" alignItems="start" paddingLeft="0">
              <Title
                font="15px"
                textAlign="start"
                color="#999999"
                hoverColor="#999999"
              >
                Already have an account?{""}
                <span
                  className="login-link"
                  onClick={() => {
                    setIsLogin(true);
                  }}
                >
                  {""} Login here
                </span>
              </Title>
            </TitleWrapper>
          </LoginContainer>
        </>
      )}
    </LoginWrapper>
  );
};

export default Login;
