import React, { ReactElement, useState } from "react";
import {
  CanvaContianer,
  Offcanva,
  MobileNavContainer,
  MobileNavWrapper,
  MobileList,
  MobileAnchorText,
} from "./subcomponents";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router";
import { getProductsByIds } from "../../redux/categories/api";
import ROUTES from "../../const/routes";

interface MobileProps {
  showCanvas: boolean;
  setshowCanvas: any;
}

const MobileHeder = ({
  setshowCanvas,
  showCanvas,
}: MobileProps): ReactElement => {
  const {
    category: { getCategoriesLists, getSubCategoryById },
  } = useSelector(
    (state: RootState) => ({
      category: state.category,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const [catName, setCatName] = useState<any>("");
  const dispatch: AppDispatch = useDispatch();

  return (
    <CanvaContianer>
      <Offcanva
        show={showCanvas}
        scroll
        backdrop
        responsive={showCanvas ? "lg" : ""}
        autoFocus
        onHide={() => {
          setshowCanvas(false);
        }}
      >
        <Offcanva.Header closeButton>Mahaadeva</Offcanva.Header>
        <Offcanva.Body>
          <MobileNavContainer>
            <MobileNavWrapper>
              {getCategoriesLists?.map((category, index) => {
                return (
                  <MobileList>
                    <MobileAnchorText
                      onClick={() => {
                        setCatName(category?.name);
                        if (category?.name === "MAHAADEVA") {
                          dispatch(
                            getProductsByIds({
                              category_id: category?.categories_id,
                              subcategory_id: "",
                            })
                          );
                          setshowCanvas(false);
                        } else if (category?.name !== "MAHAADEVA") {
                          dispatch(
                            getProductsByIds({
                              category_id: category?.categories_id,
                              subcategory_id: "",
                            })
                          )?.then((data) => {
                            navigate(ROUTES.CATEGORY_PRODUCT_LIST, {
                              state: { productList: data?.payload },
                            });
                            setshowCanvas(false);
                          });
                        }
                      }}
                      isActive={catName === category?.name}
                    >
                      {category?.name}
                    </MobileAnchorText>
                  </MobileList>
                );
              })}
            </MobileNavWrapper>
          </MobileNavContainer>
        </Offcanva.Body>
      </Offcanva>
    </CanvaContianer>
  );
};

export default MobileHeder;
