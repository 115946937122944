import React, { ReactElement } from "react";
import {
  Pagewrapper,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import { FormControls } from "./styledcomponents";
import { Footer, StyledButton } from "../../components";
import { Col, Row } from "react-bootstrap";

const Contact = (): ReactElement => {
  return (
    <Pagewrapper>
      <SectionContainer>
        <SectionWrapper>
          <Row>
            <Col lg={6}>
              <TitleWrapper textAlign="start" alignItems="start">
                <Title>Get in Touch</Title>
                <Title font="20px" fontWeight={600} className="mt-4">
                  Our Address
                </Title>
                <Subtitles font="18px">
                  Mahaadeva <br /> Mahaadeva Associates
                  <br /> Near Nesto, Vyasa Nagar, Poonkunnam
                  <br /> Thrissur, Kerela, 680002
                </Subtitles>{" "}
                <Title font="20px" fontWeight={600} className="mt-3">
                  Email
                </Title>
                <Subtitles font="18px">Mahaadeva8888@gmail.com</Subtitles>{" "}
                <Title font="20px" fontWeight={600} className="mt-3">
                  Phone Number
                </Title>
                <Subtitles font="18px">+91 8139-088850</Subtitles>
              </TitleWrapper>
            </Col>
            <Col lg={6}>
              {" "}
              <TitleWrapper textAlign="start" alignItems="start">
                <Title>Contact us</Title>
                <FormControls placeholder="Firstname" />
                <FormControls placeholder="Lastname" />
                <FormControls placeholder="Email" />
                <FormControls placeholder="Phone No." />
                <FormControls as="textarea" rows={3} placeholder="Comments" />
                <br />
                <StyledButton
                  onClick={() => {
                    alert("Response Submitted");
                  }}
                >
                  Submit
                </StyledButton>
              </TitleWrapper>
            </Col>
          </Row>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default Contact;
