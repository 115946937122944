import { ReactElement, forwardRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import { DropdownProps } from "./typings";
import { DropdownWrapper, Title, ErrorWrapper } from "./subcomponents";

const Dropdown = forwardRef(
  (
    {
      width,
      isDisabled,
      title,
      isRequired,
      singleSelect,
      handleSelect,
      error,
      dropdownList,
      placeholder,
      selectedValues,
    }: DropdownProps,
    ref: any
  ): ReactElement => {
    return (
      <DropdownWrapper
        width={width}
        isDisabled={isDisabled}
        id="editable-dropdown"
      >
        {title && (
          <Title>
            {`${title}`}
            {isRequired && <strong>*</strong>}
          </Title>
        )}
        <Multiselect
          id="input"
          singleSelect={singleSelect || false}
          displayValue="name"
          showArrow={false}
          ref={ref}
          selectedValues={selectedValues}
          placeholder={placeholder}
          onRemove={function noRefCheck() {}}
          onSelect={handleSelect}
          options={dropdownList}
          style={{
            chips: {
              fontSize: "16px",
              color: "#575757",
              borderRadius: "20px",
              fontWeight: 500,
              margin: "2px auto",
              background: "lightgrey",
            },
            multiselectContainer: {
              fontSize: "16px",
              color: "#212529",
              borderRadius: 0,
              fontWeight: 500,
            },
            option: {
              fontSize: "16px",
              color: "#212529",
              borderRadius: 0,
              paddingLeft: "10px",
              fontWeight: 500,
            },
            optionContainer: {
              background: "#fff",
            },
            inputField: {
              fontSize: "16px",
              color: "#212529",
              paddingLeft: "10px",
              borderRadius: 0,
              margin: "2px auto",
              fontWeight: 500,
            },
            searchBox: {
              borderColor: "#dee2e6",
              borderRadius: 0,
            },
          }}
        />
        <ErrorWrapper>{error}</ErrorWrapper>
      </DropdownWrapper>
    );
  }
);

export default Dropdown;
