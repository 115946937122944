import { useState, useEffect } from "react";
import {
  DropdownMenuList,
  DropdownWrapper,
  DropdownAnchor,
} from "./subcomponents";
import { DropdownProsp } from "./types";
import Aos from "aos";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router";
import ROUTES from "../../const/routes";
import { getProductsByIds } from "../../redux/categories/api";

const Dropdown = ({ dropdownName }: DropdownProsp) => {
  const {
    category: { getSubCategoryById },
  } = useSelector(
    (state: RootState) => ({
      category: state.category,
    }),
    shallowEqual
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [click, setClick] = useState(false);

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);

  return (
    <>
      <DropdownWrapper
        onClick={() => {
          setClick(!click);
        }}
        data-aos="fade-up"
        data-aos-duration="600"
      >
        {getSubCategoryById?.map((item, index) => {
          return (
            <DropdownMenuList key={index}>
              <DropdownAnchor
                onClick={() => {
                  setClick(false);
                  dispatch(
                    getProductsByIds({
                      category_id: item?.category_id,
                      subcategory_id: item?.id,
                    })
                  )?.then((data) => {
                    navigate(ROUTES.CATEGORY_PRODUCT_LIST, {
                      state: { productList: data?.payload },
                    });
                  });
                }}
              >
                {item?.name}
              </DropdownAnchor>
            </DropdownMenuList>
          );
        })}
      </DropdownWrapper>
    </>
  );
};

export default Dropdown;
