import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initalState } from "./types";
import { GetProductListResposne } from "../categories/types";

export const cartSlices = createSlice({
  name: "cart",
  initialState: initalState,
  reducers: {
    addtoCart: (state, action: PayloadAction<GetProductListResposne>) => {
      const productItem = state.products.find(
        (item) => item.product_id === action.payload.product_id
      );
      if (productItem) {
        productItem.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },
    increaseQuantity: (state, action: PayloadAction<{ id: string }>) => {
      const productItem = state.products.find(
        (item) => item.product_id === action.payload.id
      );
      if (productItem) {
        productItem.quantity++;
      }
    },
    drecreaseQuantity: (state, action: PayloadAction<{ id: string }>) => {
      const productItem = state.products.find(
        (item) => item.product_id === action.payload.id
      );
      if (productItem) {
        productItem.quantity--;
      }
    },
    deleteIteminCart: (state, action: PayloadAction<{ id: string }>) => {
      state.products = state.products.filter(
        (item) => item.product_id !== action.payload.id
      );
    },
    resetProduct: (state) => {
      state.products = [];
    },
  },
  extraReducers: {},
});

export default cartSlices.reducer;
