import { ReactElement } from "react";
import {
  ColumnWrapper,
  ImageWrapper,
  Pagewrapper,
  RowWrapper,
  Subtitles,
  Title,
  TitleWrapper,
  Images,
  SectionContainer,
  SectionWrapper,
} from "../../styledcomponents";
import { Footer, Movetop, StyledButton } from "../../../components";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../../const/routes";
import { GetProductListResposne } from "../../../redux/categories/types";
import { NewAmount, OldAmount } from "../subcomponents";
import { BASE_URL } from "../../../services";
import { useDispatch } from "react-redux";
import { addtoCart } from "../../../redux/product/actions";

const ProductDetials = (): ReactElement => {
  Movetop();

  const loacation = useLocation();
  const navigate = useNavigate();

  const product: GetProductListResposne = loacation.state?.selectedProduct;
  const finalPrice =
    Number(product?.price) -
    (Number(product?.price) * Number(`${product?.discount}`)) / 100;

  const dispatch = useDispatch();

  return (
    <Pagewrapper>
      <SectionContainer background="#fff">
        <SectionWrapper>
          <RowWrapper>
            <ColumnWrapper lg={4}>
              <ImageWrapper
                width="300px"
                height="300px"
                style={{ marginTop: "-25px" }}
              >
                <Images
                  src={`${BASE_URL}${product?.product_img}`}
                  alt="book-image"
                />
              </ImageWrapper>
            </ColumnWrapper>
            <ColumnWrapper
              lg={8}
              justifyContent="start"
              style={{ paddingLeft: "35px" }}
            >
              <TitleWrapper alignItems="start" textAlign="start">
                <Title font="2rem" fontWeight={600}>
                  {product?.product_name}
                </Title>
                <Subtitles font="1rem" color="#777" fontWeight={600}>
                  {product?.description}
                </Subtitles>
                <Row
                  style={{ width: "100%", minWidth: "900px" }}
                  className="mt-3"
                >
                  <Col lg={6}>
                    <Subtitles color="#ff6100" font="2rem" fontWeight={600}>
                      {product?.discount === "0" ? (
                        <NewAmount style={{ fontSize: "2rem" }}>
                          Rs. {finalPrice}
                        </NewAmount>
                      ) : (
                        <>
                          <OldAmount style={{ fontSize: "2rem" }}>
                            ₹ {product?.price}
                          </OldAmount>{" "}
                          <NewAmount style={{ fontSize: "2rem" }}>
                            ₹ {finalPrice}
                          </NewAmount>
                        </>
                      )}
                    </Subtitles>
                  </Col>
                  <Col lg={6}>
                    <StyledButton
                      style={{ marginTop: "-7px" }}
                      backGround="#ff6100"
                      hoverBackground="black"
                      onClick={() => {
                        if (localStorage.getItem("isLoggedIn") === "true") {
                          navigate(ROUTES.CART_LIST);
                          dispatch(
                            addtoCart({
                              ...product,
                              without_discount: product?.price,
                              discount_price:
                                (Number(product?.price) *
                                  Number(`${product?.discount}`)) /
                                100,
                              price:
                                Number(product?.price) -
                                (Number(product?.price) *
                                  Number(`${product?.discount}`)) /
                                  100,
                              quantity: 1,
                            })
                          );
                        } else {
                          alert("Please Login");
                        }
                      }}
                    >
                      {" "}
                      <i className="bx bx-cart-add"></i> Add to cart
                    </StyledButton>
                  </Col>
                </Row>
              </TitleWrapper>
            </ColumnWrapper>
          </RowWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default ProductDetials;
