import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services";
import apiendpoints from "../../const/apiendpoints";

export const getCategoriesList = createAsyncThunk(
  "categories/categoriesList",
  async (): Promise<any> => {
    const response = await api.get(apiendpoints.getCategories);
    return response?.data?.message;
  }
);

export const getSubCategoriesList = createAsyncThunk(
  "categories/subCategoriesList",
  async (): Promise<any> => {
    const response = await api.get(apiendpoints.getSubCategories);
    return response?.data?.message;
  }
);

export const getSubCategoriesByCatId = createAsyncThunk(
  "categories/subCategoriesListbyId",
  async (requestPayload: { category_id: any }): Promise<any> => {
    const response = await api.post(
      apiendpoints.getSubCategoriesbyId,
      requestPayload
    );
    return response?.data?.message;
  }
);

export const getProductsByIds = createAsyncThunk(
  "product/getProduct",
  async (requestPayload: {
    category_id: any;
    subcategory_id: any;
  }): Promise<any> => {
    const resposne = await api.post(
      apiendpoints.getProductsListByIds,
      requestPayload
    );
    return resposne?.data?.message;
  }
);

export const getAllProductLists = createAsyncThunk(
  "product/getAllProduct",
  async (): Promise<any> => {
    const response = await api.get(apiendpoints.getAllProduct);
    return response?.data?.message;
  }
);

export const getCarsoulImage = createAsyncThunk(
  "car/getCar",
  async (): Promise<any> => {
    const response = await api.get(apiendpoints.getCarsoulImage);
    return response?.data?.message;
  }
);
export const getQuotes = createAsyncThunk(
  "quotes/getQuotes",
  async (): Promise<any> => {
    const response = await api.get(apiendpoints.getQuotess);
    return response?.data?.message;
  }
);

export const getCoupons = createAsyncThunk(
  "coup/getCoup",
  async (): Promise<any> => {
    const response = await api.get("get_coupons");
    return response?.data?.message;
  }
);
