import { cartSlices } from "./reducers";

const {
  addtoCart,
  increaseQuantity,
  deleteIteminCart,
  drecreaseQuantity,
  resetProduct,
} = cartSlices.actions;

export {
  addtoCart,
  increaseQuantity,
  deleteIteminCart,
  drecreaseQuantity,
  resetProduct,
};
