import React, { ReactElement } from "react";
import { Pagewrapper } from "../../components/Header/subcomponents";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import { Footer, Movetop } from "../../components";

const Terms = (): ReactElement => {
  Movetop();
  return (
    <Pagewrapper>
      <SectionContainer>
        <SectionWrapper>
          <TitleWrapper textAlign="start" alignItems="start">
            <Title>Terms and Conditions</Title>
            <Subtitles>
              <ul>
                <li>
                  Items offered are subject to availability. In case an order is
                  placed for which we do not have available stock, we will
                  cancel the order and refund the money to the customers. We
                  will not be responsible for losses if any incurred by
                  customers due to such cancellation.
                </li>
                <li> Your browser may not support display of images.</li>
                <li>
                  The actual colors/finishes of handcrafted products may
                  marginally vary from the online display. Your browser may not
                  support display of this image.
                </li>
                <li>
                  Hand-made items are susceptible to slight variations in size.
                  Dimensions are indicative and minor differences are possible.
                </li>
                <li>
                  Prices are subject to change due and are the sole discretion
                  of Mahaadeva.
                </li>
                <li>
                  We as a merchant shall be under no liability in respect of any
                  loss or damage arising directly or indirectly out of the
                  decline of authorization for any transaction, on account of
                  the Cardholder having exceeded the preset limit mutually
                  agreed by us with our acquiring bank from time to time.
                </li>
              </ul>
            </Subtitles>
            <Title>Shipping Conditions</Title>
            <Subtitles>
              <ul>
                <li>
                  Your order will be delivered within <b>8–11</b> working days.
                </li>
                <li>
                  We provide free delivery for all orders <b>Rs 950</b> and
                  above to locations serviced by us. For orders till Rs Rs 949,
                  we charge shipping charge of <b>Rs 99</b> for delivery
                  anywhere in India.
                </li>
                <li>
                  {" "}
                  The list of locations serviced by us is available here
                  Serviceable Pincodes
                </li>
                <li>
                  {" "}
                  For orders where the delivery location is not serviceable, we
                  will cancel the order and refund the amount paid to us.
                </li>
                <li>
                  Few products that require special packing and handling,
                  separate Packing & Handling charges will be applicable. Such
                  charge, if any, will be displayed on the respective product
                  page.
                </li>
              </ul>
            </Subtitles>{" "}
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default Terms;
