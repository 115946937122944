import React, { ReactElement } from "react";
import { Pagewrapper } from "../../components/Header/subcomponents";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import { Footer, Movetop } from "../../components";

const RefundPolicy = (): ReactElement => {
  Movetop();
  return (
    <Pagewrapper>
      <SectionContainer>
        <SectionWrapper>
          <TitleWrapper textAlign="start" alignItems="start">
            <Title>Return Policy</Title>
            <Subtitles></Subtitles>
            <Title font="19px">
              We will provide replacements in the following circumstances:
            </Title>
            <Subtitles>
              <ul>
                <li> Genuine defect or damage in the product received</li>

                <li>
                  Genuine sizing issues, only for unused garments with tags.
                  Please refer to the size chart in the product page to
                  ascertain if the garment is of right size before buying.{" "}
                </li>
                <li> Package damaged in transit</li>
                <li> In case wrong item has been shipped to you</li>
              </ul>
            </Subtitles>{" "}
            <Title font="19px">
              No replacement will be given in the following cases:
            </Title>
            <Subtitles>
              <ul>
                <li>
                  In case of minor design and color variations. With
                  handcrafted/handloom products there will always be some
                  variation between products.
                </li>

                <li> Products returned in a used or damaged condition</li>
                <li>
                  {" "}
                  If the customer changes his / her mind after purchasing or on
                  receipt of the product
                </li>
                <li>
                  If the product is returned without a written confirmation from
                  us via email
                </li>
                <li>
                  {" "}
                  If the product is not returned through our courier, Blue Dart
                </li>
              </ul>
            </Subtitles>{" "}
            <br />
            <Title font="19px">
              1) What to do in case the package is damaged?
            </Title>
            <Subtitles>
              In case the package appears damaged, crushed, wet or leaked from
              the outside please do not accept the package from the delivery
              person. It is probably damage-in-transit and should be not
              accepted from the courier company. However, if you discover the
              damage once you open the parcel, please report the damage to us
              within 48 hours of the delivery of the product through email
              {""}{" "}
              <span
                className="link"
                onClick={() => {
                  window.open(
                    "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Mahaadeva8888@gmail.com"
                  );
                }}
              >
                Mahaadeva8888@gmail.com
              </span>{" "}
              describing the damage as mentioned below along with images of the
              parcel.
            </Subtitles>
            <br />
            <Title font="19px">2) What is the replacement process?</Title>
            <Subtitles>
              Step 1: All eligible replacement requests need to be notified to
              us by email to {""}{" "}
              <span
                className="link"
                onClick={() => {
                  window.open(
                    "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Mahaadeva8888@gmail.com"
                  );
                }}
              >
                Mahaadeva8888@gmail.com
              </span>{" "}
              within 48 hours of the delivery of the product. Please mention the
              following information in the email:
              <br />
              <br />
              <ul>
                <li> Mahadevan Order Number</li>
                <li> Your email ID registered with us </li>
                <li> Name of the damaged product and quantity damaged </li>
                <li> Description of the issue </li>
                <li> Clear photos of the package and the damaged products </li>
              </ul>
              <br />
              <br />
              Step 2: We will review your replacement request and confirm if the
              request is approved on email in 48 hours
              <br />
              <br /> Step 3: If replacement is approved, we will arrange to pick
              up the product through Blue Dart who will deliver it back to us{" "}
              <br />
              <br />
              Step 4: After the receipt of your parcel, we will verify the
              products and authorize it for replacement within 3 days if the
              products are found to be in proper condition.
              <br />
              <br /> Step 5: Once approved, we will ship the replacement product
              in 48 hours. In case the replacement product is not available in
              stock, we may contact you to choose a different product or refund
              the purchase cost to you. The refund process will take 7-10
              working days.
              <br />
              <br />
            </Subtitles>
            <br />
            <Title font="19px">
              3) Do you arrange for pickup of the products?
            </Title>
            <Subtitles>
              Yes, we arrange for pickups for returns that have been approved by
              us using Blue Dart courier.
            </Subtitles>{" "}
            <br />
            <Title font="19px">4) Where do I mail the returns?</Title>
            <Subtitles>
              Replacement product will be shipped within 48 hours of the receipt
              of the original item, subject to the following conditions:
            </Subtitles>{" "}
            <br />
            <Title font="19px">5) When will I receive a replacement?</Title>
            <Subtitles>
              Replacement product will be shipped within 48 hours of the receipt
              of the original item, subject to the following conditions:
              <br />
              <br />
              <ul>
                <li>
                  The products should be unused, unsoiled and unwashed. In the
                  case of Rudraksha, it should not be conditioned.
                </li>
                <li>
                  The products should have the original tags and should be in
                  the original packaging. Any returned item received by us that
                  does not meet the above-mentioned conditions would not be
                  accepted. No replacement will be provided for such products.
                </li>
              </ul>
            </Subtitles>{" "}
            <br />
            <Title font="19px">
              6) Can I choose a different product in exchange?
            </Title>
            <Subtitles>
              No, you can't choose a different product in exchange.
            </Subtitles>{" "}
            <br />
            <Title font="19px">
              7) How much time does it take to issue the refund?
            </Title>
            <Subtitles>
              The refund process will take 7-10 working days from the receipt of
              the returned goods.
            </Subtitles>{" "}
            <br />
            <Title font="19px">8) How is the refund made?</Title>
            <Subtitles>
              Refund will be sent to the same card/bank account from which the
              payment was made. In case it is not possible for our Payment
              Gateway to send the refund to the samecard/bankaccount due to the
              expiry of the refund window, we will request you to provide an
              image of a canceled cheque using which we will send the refund
              money through online fund transfer.
            </Subtitles>{" "}
            <br />
            <Title font="19px">9) Can I modify or cancel my order?</Title>
            <Subtitles>
              No, you can't modify or cancel an order once placed successfully
              on the website.
            </Subtitles>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default RefundPolicy;
