import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTruckFast,
  faChevronRight,
  faCartShopping,
  faGears,
  faMagnifyingGlass,
  faTrashAlt,
  faHeadset,
  faXmark,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { faUserCircle } from "@fortawesome/free-regular-svg-icons";

library.add(
  faTruckFast,
  faChevronRight,
  faCartShopping,
  faGears,
  faHeadset,
  faMagnifyingGlass,
  faTrashAlt,
  faUserCircle,
  faXmark,
  faChevronDown
);
