import { ReactElement, useEffect, useState } from "react";
import {
  Pagewrapper,
  SectionContainer,
  SectionWrapper,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import {
  CardContainer,
  ContactHeaderWrapper,
  ImageOpacityWrapper,
  LoadMore,
  TextWrapper,
} from "../Home/subcomponents";
import { ProductCard } from "../Home/ProductDetails/helpers";
import { useLocation, useNavigate } from "react-router";
import { GetProductListResposne } from "../../redux/categories/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Footer, Loader } from "../../components";
import ROUTES from "../../const/routes";
import { addtoCart } from "../../redux/product/actions";
import Drawer from "../../components/Drawer";
import Login from "../../container/Login";
import { getAllProductLists } from "../../redux/categories/api";

const CategoryProductList = (): ReactElement => {
  const {
    category: { isProductLoading },
  } = useSelector((state: RootState) => ({
    category: state.category,
  }));

  const location = useLocation();
  const navigate = useNavigate();
  const productList: Array<GetProductListResposne> =
    location?.state?.productList;

  const dispatch: AppDispatch = useDispatch();
  const [loginDrawer, setLoginDrawer] = useState(false);

  const PAGE_SIZE = 4;

  const [index, setIndex] = useState(0);

  const [productDetails, setProductDetails] = useState<
    Array<GetProductListResposne>
  >([]);

  useEffect(() => {
    const numberOfItems = PAGE_SIZE * (index + 1);

    const newArray: any = [];

    for (let i = 0; i < productList.length; i++) {
      if (i < numberOfItems) newArray.push(productList[i]);
    }

    setProductDetails(newArray);
  }, [productList, index]);

  useEffect(() => {
    dispatch(getAllProductLists());
  }, [dispatch]);

  return (
    <Pagewrapper>
      {" "}
      <Drawer
        show={loginDrawer}
        onClose={() => {
          setLoginDrawer(false);
        }}
        onOpen={() => {
          setLoginDrawer(false);
        }}
        component={
          <Login
            onClose={() => {
              setLoginDrawer(false);
            }}
          />
        }
      />
      <ContactHeaderWrapper>
        <ImageOpacityWrapper />
        <TextWrapper>
          <Title
            color="#fff"
            hoverColor="#fff"
            font="2.5rem"
            lineHeight="26px"
            textAlign="center"
          >
            Products List
          </Title>
        </TextWrapper>
      </ContactHeaderWrapper>
      <SectionContainer
        background="#dfdfdf66"
        style={{ height: productList?.length ? "" : "50vh" }}
      >
        {isProductLoading ? (
          <Loader />
        ) : (
          <SectionWrapper>
            {productDetails?.length ? (
              <CardContainer>
                {productDetails?.map((product, i) => {
                  return (
                    <ProductCard
                      key={i}
                      title={product?.product_name}
                      buttonLoader={false}
                      product={product}
                      onProductClick={() => {
                        navigate(ROUTES.PRODUCT_DETAILS, {
                          state: { selectedProduct: product },
                        });
                      }}
                      onClick={() => {
                        if (localStorage.getItem("isLoggedIn") === "true") {
                          dispatch(
                            addtoCart({
                              ...product,
                              without_discount: product?.price,
                              discount_price:
                                (Number(product?.price) *
                                  Number(`${product?.discount}`)) /
                                100,
                              price:
                                Number(product?.price) -
                                (Number(product?.price) *
                                  Number(`${product?.discount}`)) /
                                  100,
                              quantity: 1,
                            })
                          );
                          navigate(ROUTES.CART_LIST, {
                            state: { selectedProduct: product },
                          });
                        } else {
                          setLoginDrawer(true);
                        }
                      }}
                    />
                  );
                })}
              </CardContainer>
            ) : (
              <TitleWrapper>
                <Title color="grey" font="16px">
                  No Products available
                </Title>
              </TitleWrapper>
            )}
            {productList?.length > 4 &&
              productList?.length !== productDetails?.length && (
                <>
                  <LoadMore onClick={() => setIndex(index + 1)}>
                    Loadmore....
                  </LoadMore>
                </>
              )}
          </SectionWrapper>
        )}
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default CategoryProductList;
