import axios from "axios";

const getBearertoken = () => sessionStorage.getItem("bearer-token");

export const BASE_URL = "https://api.mahaadeva.com/";
//https://api.mahaadeva.com/
//"http://13.201.28.200:4000/";
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "content-type": "application/json; charset=utf-8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "https://api.mahaadeva.com/",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

api.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = `Bearer ${getBearertoken()}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (error?.response?.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
