import { FormControl } from "react-bootstrap";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  width: 100%;
  position: relative;
  transition: all 0.5s;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
`;

export const LoginHeader = styled.div`
  height: 70px;
  min-height: 70px;
  width: 340px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid gray;
  .x-icon {
    font-size: 20px;
    cursor: pointer;
  }
  a {
    color: #999999;
  }
`;

export const HeaderTitle = styled.span`
  font-size: 17px;
  color: black;
  align-items: center;
  text-align: center;
  display: flex;
  font-weight: 500;
  text-transform: uppercase;
`;

export const FormInput = styled(FormControl)`
  height: 40px;
  border-radius: 0;
  ::placeholder {
    font-size: 15px;
    font-weight: 500;
  }
`;

export const LoginQuotes = styled.h6`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 320px;
  text-align: center;
  justify-content: center;
`;
