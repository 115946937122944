import styled from "styled-components";

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  padding-right: 10px;
`;

export const DecreamentButton = styled.button`
  background-color: #eeeeee;
  align-items: center;
  border-radius: 6px;
  border: none;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  color: grey;
  cursor: pointer;
  justify-content: center;
  line-height: 1.5;
  overflow: hidden;
  padding: 9px 20px;
  position: relative;
  text-align: center;
  z-index: 1;
  &:hover,
  &:active&:focus {
    color: black;
  }
  &:active {
    transform: scale(0.9);
  }
`;
export const CountValue = styled.div`
  align-items: center;
  display: inline-flex;
  font-size: 17px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  width: 55px;
  justify-content: center;
  line-height: 1.5;
  overflow: hidden;
  padding: 9px 20px;
  position: relative;
  text-align: center;
`;
export const IncreamentButton = styled.button`
  background-color: #eeeeee;
  align-items: center;
  border-radius: 6px;
  display: inline-flex;
  border: none;
  font-size: 20px;
  font-weight: 600;
  color: grey;
  cursor: pointer;
  justify-content: center;
  line-height: 1.5;
  overflow: hidden;
  padding: 9px 20px;
  position: relative;
  text-align: center;
  z-index: 1;
  &:hover,
  &:active&:focus {
    color: black;
  }
  &:active {
    transform: scale(0.9);
  }
`;
