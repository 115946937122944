import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GetCarsoulImage,
  GetCategoriesResponse,
  GetCouponsProsp,
  GetProductListResposne,
  GetQuotedProsp,
  GetSubCategoryById,
  initialState,
} from "./types";
import {
  getAllProductLists,
  getCarsoulImage,
  getCategoriesList,
  getCoupons,
  getProductsByIds,
  getQuotes,
  getSubCategoriesByCatId,
} from "./api";

export const categoriesSlices = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    updateSelectedCoupon: (state, action: PayloadAction<GetCouponsProsp>) => {
      state.setSelectedCoupon = action?.payload;
    },
    updateSelectedCouponEmpty: (state, action: PayloadAction<any>) => {
      state.setSelectedCoupon = action?.payload;
    },
  },
  extraReducers: {
    [getCategoriesList.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getCategoriesList.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetCategoriesResponse>>
    ) => {
      state.isLoading = false;
      state.getCategoriesLists = action.payload;
    },
    [getCategoriesList.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getSubCategoriesByCatId.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getSubCategoriesByCatId.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetSubCategoryById>>
    ) => {
      state.isLoading = false;
      state.getSubCategoryById = action.payload;
    },
    [getSubCategoriesByCatId.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getProductsByIds.pending.toString()]: (state) => {
      state.isProductLoading = true;
    },
    [getProductsByIds.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetProductListResposne>>
    ) => {
      state.isProductLoading = false;
      state.getProductsListsbyId = action.payload;
    },
    [getProductsByIds.rejected.toString()]: (state) => {
      state.isProductLoading = false;
    },
    [getAllProductLists.pending.toString()]: (state) => {
      state.isProductLoading = true;
    },
    [getAllProductLists.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetProductListResposne>>
    ) => {
      state.isProductLoading = false;
      state.getAllproduct = action.payload;
    },
    [getAllProductLists.rejected.toString()]: (state) => {
      state.isProductLoading = false;
    },
    [getCarsoulImage.pending.toString()]: (state) => {
      state.isProductLoading = true;
    },
    [getCarsoulImage.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetCarsoulImage>>
    ) => {
      state.isProductLoading = false;
      state.getCarsoulImages = action.payload;
    },
    [getCarsoulImage.rejected.toString()]: (state) => {
      state.isProductLoading = false;
    },
    [getQuotes.pending.toString()]: (state) => {
      state.isProductLoading = true;
    },
    [getQuotes.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetQuotedProsp>>
    ) => {
      state.isProductLoading = false;
      state.getQuotesList = action.payload;
    },
    [getQuotes.rejected.toString()]: (state) => {
      state.isProductLoading = false;
    },
    [getCoupons.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getCoupons.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetCouponsProsp>>
    ) => {
      state.isLoading = false;
      state.getCouponsList = action.payload;
    },
    [getCoupons.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default categoriesSlices.reducer;
