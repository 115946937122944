import React from "react";
import { Header } from "./components";
import { createGlobalStyle } from "styled-components";
import { Route, Routes } from "react-router";
import {
  Home,
  ProductDetials,
  CartList,
  CheckoutDetials,
  CategoryProductList,
  PrivacyPolicy,
  RefundPolicy,
  Terms,
  Aboutus,
  Contact,
} from "./pages";
import ROUTES from "./const/routes";

export const GlobalStyles = createGlobalStyle`
 
body {
    font-family: 'Quicksand', sans-serif;
}

.table tbody>tr>th,
.table tfoot>tr>th,
.table tbody>tr>td,
.table tfoot>tr>td {
    line-height: 1.25;
    padding: 20px 10px;
    text-align: center;
    align-items: center;
    vertical-align: middle;
}

.table thead>tr>th,
.table thead>tr {
    line-height: 1.25;
    padding: 13px 4px;
    background: grey;
    color: white;
    text-align: center;
    font-weight: 600;
}
.slick-prev:before, .slick-next:before{
  background-color: transparent;
  color: grey;
} 
#scroll-container { 
  overflow: hidden;
  width: 100%;
  display: flex;
  background-color: black;
  flex-wrap: nowrap;
  color: white;
}

#scroll-text { 
  transform: translateX(100%);
   padding: 5px;
  animation: my-animation 40s linear infinite;
}

 
@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
`;

function App() {
  return (
    <>
      <Header /> <GlobalStyles />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.PRODUCT_DETAILS} element={<ProductDetials />} />
        <Route path={ROUTES.CART_LIST} element={<CartList />} />
        <Route path={ROUTES.CHECKOUT_DETAILS} element={<CheckoutDetials />} />
        <Route
          path={ROUTES.CATEGORY_PRODUCT_LIST}
          element={<CategoryProductList />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
