import { FormControl } from "react-bootstrap";
import styled from "styled-components";

export const FormControls = styled(FormControl)`
  margin: 15px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 45px;
  border-color: #f78f14;
`;
