import React, { ReactElement } from "react";
import { Pagewrapper } from "../../components/Header/subcomponents";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import { Footer, Movetop } from "../../components";

const Aboutus = (): ReactElement => {
  Movetop();
  return (
    <Pagewrapper>
      <SectionContainer>
        <SectionWrapper>
          <TitleWrapper textAlign="center" alignItems="center">
            <Title>About us</Title>
            <Title font="20px" color="blue" className="mt-4">
              "The business is the <br /> wellbeing of everyone. <br />
              Loga Samastha Sukheno bavanthu. "
            </Title>
            <br />
            <Subtitles>
              Mahaadeva envisions a collective celebration of life itself. For
              those who seek Lord Mahaadeva every moment of life. Mahaadevans
              let’s colour the world with all colours of life & turn in the zest
              for life in his profoundness. <br /> <br />
              From eco-friendly products such as woodwork, bags and baskets made
              from intricately woven natural fibres to an extensive collection
              of metal art, clothing, health and wellness products, from
              all-natural vegetable oil soaps and shampoos we provide a natural
              connected way of life.
            </Subtitles>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default Aboutus;
