/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import {
  AnchorText,
  NavbarContainer,
  NavbarList,
  NavbarWrapper,
  ProfileListWrapper,
  ProfileContainer,
  ProfileImage,
  ProfilebarList,
  HeaderWrapper,
  ProfileWrapper,
  FormInput,
  FormWrapper,
  SearchButton,
  FontIcons,
  DropdownContainer,
  DropListwrapper,
  HamburgerMenu,
  SearchWrapper,
  UserDetailsWrapper,
  SerachQuotes,
  SubBanner,
} from "./subcomponents";
import { Subtitles, Title } from "../../pages/styledcomponents";
import ProfilePhoto from "../../assests/user.png";
import Dropdown from "./dropdown";
import { useNavigate } from "react-router";
import Drawer from "../Drawer";
import Login from "../../container/Login";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getCategoriesList,
  getProductsByIds,
  getQuotes,
  getSubCategoriesByCatId,
} from "../../redux/categories/api";
import { AppDispatch, RootState } from "../../redux/store";
import ROUTES from "../../const/routes";
import { BASE_URL } from "../../services";
import Aos from "aos";
import MobileHeder from "./mobileHeader";

const Header = () => {
  const {
    category: {
      getCategoriesLists,
      getSubCategoryById,
      getAllproduct,
      getQuotesList,
    },
    cart: { products },
  } = useSelector(
    (state: RootState) => ({
      category: state.category,
      cart: state.cart,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showDp, setShowDp] = useState(false);
  const [search, setSearch] = useState("");

  document.addEventListener("scroll", (event: any) => {
    const scrollPosition = event.target.scrollingElement.scrollTop;
    setScrollPosition(scrollPosition);
  });

  const [catName, setCatName] = useState<any>("");

  const [dropdown, setDropdown] = useState("");
  const [loginDrawer, setLoginDrawer] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const localMessage: any = localStorage.getItem("message");
  const userDetails = JSON.parse(localMessage);

  function setFalseWhenclickOutside(ref: any) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDp(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  setFalseWhenclickOutside(wrapperRef);

  useEffect(() => {
    dispatch(getCategoriesList());
    dispatch(getQuotes());
  }, [dispatch]);

  useEffect(() => {
    setCatName(getCategoriesLists?.[0]?.categories_id);
  }, [getCategoriesLists]);

  useEffect(() => {
    if (search === "") {
      setShowDp(false);
    }
  }, [search]);

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);

  useEffect(() => {
    if (getCategoriesLists?.[0]?.categories_id) {
      dispatch(
        getProductsByIds({
          category_id: getCategoriesLists?.[0]?.categories_id,
          subcategory_id: "",
        })
      );
    }
  }, [dispatch, getCategoriesLists]);

  const [showCanvas, setshowCanvas] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  return (
    <HeaderWrapper>
      <Drawer
        show={loginDrawer}
        onClose={() => {
          setLoginDrawer(false);
        }}
        onOpen={() => {
          setLoginDrawer(false);
        }}
        component={
          <Login
            onClose={() => {
              setLoginDrawer(false);
            }}
          />
        }
      />
      <ProfileContainer>
        <ProfileWrapper>
          {" "}
          <HamburgerMenu
            onClick={() => {
              setshowCanvas(!showCanvas);
            }}
            className={!showCanvas ? "bx bx-menu" : "bx bx-x"}
            title="menu"
          ></HamburgerMenu>
          <FormWrapper>
            <FormInput
              placeholder="Search"
              onChange={(e: any) => {
                setShowDp(true);
                setSearch(e?.target?.value);
              }}
            />
            <SearchButton>
              <FontIcons
                icon={["fas", "magnifying-glass"]}
                left="0"
                top="-4px"
              />
            </SearchButton>
            {showDp && (
              <DropListwrapper
                data-aos="fade-up"
                data-aos-duration="600"
                ref={wrapperRef}
              >
                {getAllproduct
                  ?.filter((data) => {
                    if (search === "") {
                      return data;
                    } else if (
                      data?.product_name?.toLowerCase().includes(search)
                    ) {
                      return data;
                    }
                  })
                  ?.map((list, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        console.log("ee");
                        alert("erer");
                        navigate(ROUTES.PRODUCT_DETAILS, {
                          state: { selectedProduct: list },
                        });
                      }}
                    >
                      <li>
                        {" "}
                        <div>
                          <img src={`${BASE_URL}${list?.product_img}`} alt="" />
                        </div>
                        {list?.product_name}
                      </li>
                    </div>
                  ))}
              </DropListwrapper>
            )}
            <SerachQuotes>{[...getQuotesList]?.reverse()[0]?.q1}</SerachQuotes>
          </FormWrapper>{" "}
          <div style={{ width: "500px" }}></div>
          {/* <LogoWrapper>
            <Logo
              src={HeaderLogo}
              onClick={() => {
                navigate("/");
              }}
            />
          </LogoWrapper> */}
          <ProfileListWrapper ref={wrapperRef}>
            <NavbarWrapper>
              <ProfilebarList
                onClick={() => {
                  navigate(ROUTES.CART_LIST);
                }}
              >
                <FontIcons icon={["fas", "cart-shopping"]} />
                <span className="badge">{products?.length}</span>
              </ProfilebarList>
              <ProfilebarList>
                <ProfileImage
                  src={ProfilePhoto}
                  onClick={() => {
                    localStorage.getItem("isLoggedIn") === "true"
                      ? setShowProfile(!showProfile)
                      : setShowProfile(false);
                    localStorage.getItem("isLoggedIn") === "true"
                      ? setLoginDrawer(false)
                      : setLoginDrawer(true);
                  }}
                />{" "}
              </ProfilebarList>
              {showProfile && (
                <UserDetailsWrapper>
                  <span
                    className="profiledetails"
                    onClick={() => {
                      localStorage.getItem("isLoggedIn") === "true"
                        ? setLoginDrawer(false)
                        : setLoginDrawer(true);
                    }}
                  >
                    <Title font="1.125rem" fontWeight={600} color="#282828">
                      {userDetails?.[0]?.first_name}{" "}
                      {userDetails?.[0]?.last_name || "user"}
                    </Title>
                    <Subtitles font="12px" fontWeight={400} color="#8f8f8f">
                      {userDetails?.[0]?.email || "user@gmail.com"}
                    </Subtitles>
                  </span>
                  <>
                    {localStorage.getItem("isLoggedIn") === "true" && (
                      <i
                        className="bx bx-power-off logout"
                        title="Logout"
                        onClick={() => {
                          localStorage.clear();
                          window.location.reload();
                          navigate("/");
                        }}
                      ></i>
                    )}
                  </>
                </UserDetailsWrapper>
              )}
            </NavbarWrapper>
          </ProfileListWrapper>{" "}
        </ProfileWrapper>
      </ProfileContainer>
      {/* --------------------------------------------------------navbar---------------------------------------------- */}
      <SearchWrapper>
        <FormInput
          placeholder="Search"
          onChange={(e: any) => {
            setShowDp(true);
            setSearch(e?.target?.value);
          }}
        />
        <SearchButton>
          <FontIcons icon={["fas", "magnifying-glass"]} left="0" top="-4px" />
        </SearchButton>
        {showDp && (
          <DropListwrapper
            data-aos="fade-up"
            data-aos-duration="600"
            ref={wrapperRef}
          >
            {getAllproduct
              ?.filter((data) => {
                if (search === "") {
                  return data;
                } else if (data?.product_name?.toLowerCase().includes(search)) {
                  return data;
                }
              })
              ?.map((list, index) => (
                <div key={index}>
                  <li
                    onClick={() => {
                      navigate(ROUTES.PRODUCT_DETAILS, {
                        state: { selectedProduct: list },
                      });
                      setShowDp(false);
                    }}
                  >
                    {" "}
                    <div>
                      <img src={`${BASE_URL}${list?.product_img}`} alt="" />
                    </div>
                    {list?.product_name}
                  </li>
                </div>
              ))}
          </DropListwrapper>
        )}

        <SerachQuotes style={{ height: "60%" }}>
          {[...getQuotesList]?.reverse()[0]?.q1}
        </SerachQuotes>
      </SearchWrapper>{" "}
      <MobileHeder setshowCanvas={setshowCanvas} showCanvas={showCanvas} />
      <div id="scroll-container">
        <div id="scroll-text">{[...getQuotesList]?.reverse()[0]?.q3}</div>
      </div>
      <NavbarContainer position={scrollPosition > 15 ? "fixed" : "relative"}>
        <NavbarWrapper>
          {getCategoriesLists?.map((category, i) => {
            return (
              <NavbarList
                key={i}
                onMouseEnter={() => {
                  dispatch(
                    getSubCategoriesByCatId({
                      category_id: `${category?.categories_id}`,
                    })
                  );
                  if (window.innerWidth < 960) {
                    setDropdown("");
                  } else {
                    setDropdown(category?.name);
                  }
                }}
                onMouseLeave={() => {
                  if (window.innerWidth < 960) {
                    setDropdown("");
                  } else {
                    setDropdown("");
                  }
                }}
              >
                <AnchorText
                  onClick={() => {
                    setCatName(category?.name);
                    navigate(ROUTES.HOME);
                    if (category?.name === "MAHAADEVA") {
                      dispatch(
                        getProductsByIds({
                          category_id: category?.categories_id,
                          subcategory_id: "",
                        })
                      );
                    } else if (category?.name !== "MAHAADEVA") {
                      dispatch(
                        getProductsByIds({
                          category_id: category?.categories_id,
                          subcategory_id: "",
                        })
                      )?.then((data) => {
                        navigate(ROUTES.CATEGORY_PRODUCT_LIST, {
                          state: { productList: data?.payload },
                        });
                      });
                    }
                  }}
                  isActive={catName === category?.name}
                >
                  {category?.name}
                </AnchorText>
                <DropdownContainer>
                  {dropdown === category?.name && getSubCategoryById?.length ? (
                    <Dropdown dropdownName={dropdown} />
                  ) : (
                    ""
                  )}
                </DropdownContainer>
              </NavbarList>
            );
          })}
        </NavbarWrapper>
      </NavbarContainer>
    </HeaderWrapper>
  );
};

export default Header;
