export interface AddUserProps {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  state: string;
  status: number;
}

export interface GetOrderResposne {
  amount: number;
  amount_due: number;
  created_at: any;
  currency: string;
  entity: string;
  id: string;
  notes: { phone_number: string };
  phone_number: string;
  offer_id: string;
  receipt: string;
  status: string;
}

export interface UserLoginResposne {
  created_at: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  password: string;
  state: string;
  status: number;
}

export interface ApiSuccessResponse {
  status_code: number;
  message: string;
  success: any;
}

export interface InitialState {
  isLoading: boolean;
  isRejected: boolean;
  addUser: AddUserProps;
  addUsersResposne: ApiSuccessResponse | null;
  loginResposne: ApiSuccessResponse | null;
  getOrderId: GetOrderResposne | null;
  userDetails: Array<UserLoginResposne>;
}

export const initialState: InitialState = {
  isLoading: false,
  addUser: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    state: "",
    status: 1,
  },
  addUsersResposne: null,
  isRejected: false,
  getOrderId: null,
  loginResposne: null,
  userDetails: [],
};
