import styled from "styled-components";
import { FormControl, Offcanvas } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Pagewrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden !important;
`;

export const HeaderWrapper = styled.header`
  position: relative;
  background: #f7f7f7;
  animation: headerSlideDown 0.95s ease 0s normal forwards 1 running;
  @keyframes headerSlideDown {
    0% {
      margin-top: -150px;
    }
    100% {
      margin-top: 0;
    }
  }
`;

export const ProfileContainer = styled.nav`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 120px;
  width: 100%;
  min-width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin: 0 auto;
`;

export const ProfileWrapper = styled.nav`
  width: 95%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const ProfileListWrapper = styled.div`
  position: relative;
  right: -2.5%;
  z-index: 0;
  .logout {
    position: relative;
    left: 60px;
    font-size: 30px;
    cursor: pointer;
  }
`;

export const LogoWrapper = styled.div`
  height: 100px;
  width: 100px;
`;

export const Logo = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: contain;
  transition: all 0.8s;
`;

export const ProfileImageBorder = styled.div`
  border: 1px solid red;
  border-radius: 50%;
`;

export const ProfileImage = styled.img`
  border-radius: 6px;
  height: 38px;
  width: 38px;
`;

export const ProfilebarList = styled.li`
  transition: all 0.3s ease;
  margin: 0 25px;
  position: relative;
  display: inline-flex;
  z-index: 111;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease;
  }

  .badge {
    font-size: 12px;
    height: 25px;
    line-height: 22px;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #223553;
    left: 3px;
    top: -15px;
    width: 25px;
    border-radius: 50%;
  }
`;

export const FontIcons = styled(FontAwesomeIcon)<{
  left?: string;
  top?: string;
}>`
  position: relative;
  left: ${({ left }) => (left ? left : "-10px")};
  top: ${({ top }) => (top ? top : null)};
`;

export const SearchButton = styled(Button)<any>`
  background: transparent;
  color: grey;
  height: 40px;
  justify-content: center;
  line-height: 48px;
  padding: 0;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -3px;
  text-align: center;
  width: 40px;
  margin-top: 5px;
  z-index: 9;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: lightgray;
  }
`;

export const FormWrapper = styled.div`
  width: 25%;
  position: relative;
  left: 0;
  @media (max-width: 850px) {
    display: none;
  }
`;

export const SerachQuotes = styled.h5`
  position: absolute;
  top: 38px;
  font-size: 17px;
  width: 100%;
  height: 100%;
  display: flex;
  color: blue;
  align-items: center; /** Y-axis align **/
  justify-content: center; /** X-axis align **/
`;

export const FormInput = styled(FormControl)`
  border-radius: 6px;
  border: none;
  height: 45px;
  background: #fff;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    background: #fff;
  }
`;

//
//NavbarList
//

export const NavbarContainer = styled.nav<{ position: string }>`
  position: ${({ position }) => (position ? position : "relative")};
  background: ${({ position }) => (position === "fixed" ? "white" : "null")};
  top: 0;
  height: 60px;
  width: 100%;
  max-width: 100%;
  display: flex;
  transition: all 0.3s ease;
  align-items: center;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
  margin-inline-end: auto;
  margin-inline-start: auto;
  text-align: center;
  z-index: 111;
  justify-content: center;
  ${({ position }) =>
    position === "fixed" &&
    `
   box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  animation: headerSlideDown 0.95s ease 0s normal forwards 1 running;
  @keyframes headerSlideDown {
    0% {
      margin-top: -150px;
    }
    100% {
      margin-top: 0;
    }
  }
  `}
  @media (max-width: 850px) {
    display: none;
  }
`;

export const NavbarWrapper = styled.ul`
  display: flex;
  width: 97%;
  min-width: 97%;
  position: relative;
  right: 15px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 13px;
  list-style: none;
  .profiledetails {
    padding-top: 17px;
    text-align: start;
    cursor: pointer;
  }
`;

export const NavbarList = styled.li`
  height: 60px;
  display: flex;
  transition: all 0.3s ease;
  vertical-align: baseline;
  &:hover {
    text-shadow: none;
  }
`;

export const AnchorText = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? "#ff6100" : "#282828")};
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: all 0.3s ease;
  vertical-align: baseline;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  border-bottom: ${({ isActive }) => (isActive ? "1px solid #ff6100" : "none")};
  font-weight: 600;
  padding: 0;
  cursor: pointer;
  font-size: 13.5px;
  line-height: 15px;
  text-transform: uppercase;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    color: #f78f14;
    background-color: transparent;
    i {
      color: #f78f14;
    }
  }
  i {
    font-size: 17px;
    font-weight: 900;
    margin-top: -5px;
    vertical-align: middle;
  }
`;

export const DropdownContainer = styled.div<{ right?: string; top?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  transition: all 10s;
`;

export const DropdownWrapper = styled.ul`
  width: auto;
  position: absolute;
  top: 60px;
  background: #fff;
  z-index: 1000;
  list-style: none;
  text-align: start;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  padding: 15px 0;
  transition: all 5s;
  width: 300px;
`;

export const DropdownMenuList = styled.li`
  background: #ffffff;
  margin-right: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
  &:hover {
    border-radius: 3px;
  }
`;

export const DropdownAnchor = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #242864;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  padding: 5px 10px 10px;
  &:hover {
    transition: all 0.2s;
    color: #f78f14;
  }
`;

export const DropListwrapper = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50px;
  width: 400px;
  border-radius: 5px;
  z-index: 1111;
  height: auto;
  max-height: 400px;
  overflow: auto;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    padding-right: 20px;
  }
  li {
    list-style: none;
    cursor: pointer;
    padding: 5px;
    font-weight: 700;
    border-top: 1px solid lightgrey;
    display: flex;
    text-align: center;
    align-items: center;
    &:hover {
      background-color: #f78f14;
      color: white;
    }
  }
`;

export const UserDetailsWrapper = styled.div`
  width: 200px;
  height: auto;
  background-color: #fff;
  position: absolute;
  border-radius: 10px;
  display: flex;
  z-index: 1111;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: -40px;
  cursor: pointer;
  padding: 10px;
  right: 85px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

//----------------------------------------------------------mobile-css

export const CanvaContianer = styled.div`
  position: relative;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const Offcanva = styled(Offcanvas)``;

export const HamburgerMenu = styled.i`
  position: relative;
  background-color: transparent;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  font-size: 30px;
  height: 40px;
  display: flex;
  justify-items: center;
  text-align: center;
  align-items: center;
  margin-left: 20px;
  @media (min-width: 850px) {
    display: none;
  }
`;

export const SearchWrapper = styled.div`
  margin: 15px;
  height: 100px;
  position: relative;
  @media (min-width: 850px) {
    display: none;
  }
`;

export const MobileNavContainer = styled.div`
  position: relative;
`;

export const MobileNavWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: start;
  list-style: none;
`;

export const MobileList = styled.li`
  padding: 20px;
  font-weight: 700;
`;

export const MobileDropdown = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  top: 10px;
`;

export const MobileDropdownMenuList = styled.li`
  margin-right: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
  &:hover {
    border-radius: 3px;
  }
`;

export const MobileAnchorText = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? "#ff6100" : "#282828")};
  text-decoration: none;
  position: relative;
  font-weight: 600;
  font-style: normal;
  border-bottom: ${({ isActive }) => (isActive ? "1px solid #ff6100" : "none")};
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 15px;
  text-transform: uppercase;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  &:hover {
    transition: all 0.2s;
    color: #ff6100;
    background-color: transparent;
    i {
      color: #ff6100;
    }
  }
  i {
    font-size: 17px;
    font-weight: 900;
    margin-top: -2px;
    vertical-align: middle;
  }
`;

export const SubBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  background: #f7f7f7f7;
  color: black;
  padding: 8px;
  width: 100%;
  top: -8px;
  position: relative;
  z-index: 1;
  background-color: black;
  span {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    width: 80%;
    font-size: 15px;
    color: #ffff;
    transform: translateX(100%);
    animation: move 50s linear infinite;
  }

  @keyframes move {
    to {
      transform: translateX(-100%);
    }
  }
`;
