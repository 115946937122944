import styled from "styled-components";
import { FontProsp, ImagesProps, WrapperProsp } from "./types";
import { Row, Col, Table } from "react-bootstrap";

export const Pagewrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden !important;
`;

export const SectionContainer = styled.section<{
  background?: string;
  padding?: string;
}>`
  width: 100%;
  height: auto;
  background-color: ${({ background }) => (background ? background : "#fff")};
  padding: ${({ padding }) => (padding ? padding : "50px 0")};
`;

export const SectionWrapper = styled.div<{ width?: string }>`
  margin: 0 auto;
  max-width: 1500px;
  height: auto;
  width: ${({ width }) => (width ? width : "90%")};
  .borders {
    border-left: 5px solid #ff6100;
    border-radius: 3px;
    padding-right: 10px;
  }
  span {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
  }
`;

export const ImageWrapper = styled.div<ImagesProps>`
  min-width: ${({ width }) => (width ? width : "350px")};
  width: ${({ width }) => (width ? width : "350px")};
  height: ${({ height }) => (height ? height : "350px")};
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid lightgrey;
  padding: 5px;
  @media (max-width: 1000px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const Images = styled.img<ImagesProps>`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}` : "10px"};
  transition: 0.3s;
`;

export const TitleWrapper = styled.div<WrapperProsp>`
  margin: ${({ margin }) => (margin ? margin : "20px 0")};
  display: flex;
  height: ${({ height }) => (height ? height : null)};
  width: ${({ width }) => (width ? width : null)};
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
  a {
    color: #8d8c8c;
  }
`;

export const Title = styled.h1<FontProsp>`
  font-size: ${({ font }) => (font ? font : `36px`)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  display: flex;
  flex-wrap: wrap;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  .span {
    color: #ff4880;
    font-style: italic;
  }
  .login-link {
    color: #999999;
    cursor: pointer;
    text-decoration: underline;
  }
  &:hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : "black")};
  }
`;

export const Subtitles = styled.p<FontProsp>`
  font-size: ${({ font }) => (font ? font : null)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : null};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
  li::marker {
    color: black;
    font-weight: bold;
    display: inline-block;
  }
  .link {
    color: blue;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledText = styled.h4<FontProsp>`
  font-size: ${({ font }) => (font ? font : null)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
`;

export const RowWrapper = styled(Row)`
  display: flex;
  margin: ${({ margin }) => (margin ? margin : "20px auto")};
`;

export const ColumnWrapper = styled(Col)`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
`;

export const Tables = styled(Table)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 500;
`;

export const TableHeader = styled.thead``;
