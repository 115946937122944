import React, { ReactElement, useState } from "react";
import {
  FooterAnchortext,
  FooterBottom,
  FooterBottomContainer,
  FooterColumn,
  FooterContainer,
  FooterWrapper,
  ImageOpacityWrapper,
  ListWrapper,
} from "./subcomponents";
import { Title, TitleWrapper } from "../../pages/styledcomponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Drawer from "../Drawer";
import Login from "../../container/Login";
import { useNavigate } from "react-router";

const Footer = (): ReactElement => {
  const [loginDrawer, setLoginDrawer] = useState(false);
  const navigate = useNavigate();
  return (
    <FooterContainer>
      {" "}
      <ImageOpacityWrapper />
      <Drawer
        show={loginDrawer}
        onClose={() => {
          setLoginDrawer(false);
        }}
        onOpen={() => {
          setLoginDrawer(false);
        }}
        component={
          <Login
            onClose={() => {
              setLoginDrawer(false);
            }}
          />
        }
      />
      <FooterWrapper>
        {" "}
        <FooterColumn style={{ marginLeft: "10%" }} lg={2}>
          <TitleWrapper alignItems="start" textAlign="start">
            <Title
              font="1.2rem"
              fontWeight={600}
              lineHeight="24px"
              color="#fff"
              hoverColor="#fff"
            >
              Stay in touch
            </Title>
            <ListWrapper>
              <FooterAnchortext>
                <i
                  className="bx bxl-facebook"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/people/Mahaadeva-Maharaj/pfbid02n1c7PrWpxhPYPAeZV1YeN2f7hhgubTAPi3x9aRbiw2wJPcbgd56Bik2Fcyh5h5fGl/?mibextid=ZbWKwL"
                    );
                  }}
                ></i>
                <i
                  className="bx bxl-instagram"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    window.open("https://www.instagram.com/mahaadevamaharaj/");
                  }}
                ></i>
                <i
                  className="bx bxl-youtube"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    window.open("https://www.youtube.com/@Mahaadeva-hm6nc");
                  }}
                ></i>
              </FooterAnchortext>
            </ListWrapper>
          </TitleWrapper>
        </FooterColumn>
        <FooterColumn style={{ marginLeft: "1%" }} lg={2}>
          <TitleWrapper alignItems="start" textAlign="start">
            <Title
              font="1.2rem"
              fontWeight={600}
              lineHeight="24px"
              color="#fff"
              hoverColor="#fff"
            >
              Help
            </Title>
            <ListWrapper>
              {" "}
              <FooterAnchortext
                onClick={() => {
                  navigate("/terms");
                }}
              >
                <FontAwesomeIcon icon={["fas", "chevron-right"]} /> Terms and
                Shipping Conditions
              </FooterAnchortext>{" "}
              <FooterAnchortext
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                <FontAwesomeIcon icon={["fas", "chevron-right"]} /> Privacy
                Policy
              </FooterAnchortext>{" "}
              <FooterAnchortext
                onClick={() => {
                  navigate("/refund-policy");
                }}
              >
                <FontAwesomeIcon icon={["fas", "chevron-right"]} /> Refund and
                Cancellations
              </FooterAnchortext>
              <FooterAnchortext
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                {""}Contact us
              </FooterAnchortext>
              {/* <FooterAnchortext>
                <FontAwesomeIcon icon={["fas", "chevron-right"]} /> FAQ
              </FooterAnchortext> */}
            </ListWrapper>
          </TitleWrapper>
        </FooterColumn>
        <FooterColumn>
          <TitleWrapper alignItems="start" textAlign="start">
            <Title
              font="1.2rem"
              fontWeight={600}
              lineHeight="24px"
              color="#fff"
              hoverColor="#fff"
            >
              Mahaadeva
            </Title>
            <ListWrapper>
              <FooterAnchortext
                onClick={() => {
                  setLoginDrawer(true);
                }}
              >
                <FontAwesomeIcon icon={["fas", "chevron-right"]} /> Login
              </FooterAnchortext>
              <FooterAnchortext
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                <FontAwesomeIcon icon={["fas", "chevron-right"]} /> About Us
              </FooterAnchortext>
            </ListWrapper>
          </TitleWrapper>
        </FooterColumn>
        <FooterBottom className="mt-4">
          <FooterBottomContainer style={{ marginLeft: "9%" }}>
            <div>© 2024, MAHAADEVA ASSOCIATES - All rights reserved</div>
          </FooterBottomContainer>
        </FooterBottom>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
