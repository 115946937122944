import React, { ReactElement } from "react";
import { Pagewrapper } from "../../components/Header/subcomponents";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../styledcomponents";
import { Footer, Movetop } from "../../components";

const PrivacyPolicy = (): ReactElement => {
  Movetop();
  return (
    <Pagewrapper>
      <SectionContainer>
        <SectionWrapper>
          <TitleWrapper textAlign="start" alignItems="start">
            <Title>Privacy Policy</Title>
            <Subtitles>
              This privacy policy sets out how Mahaadeva uses and protects any
              information that you give Mahaadeva when you use this website.
              Mahaadeva is committed to ensuring that your privacy is protected.
              Should we ask you to provide certain information by which you can
              be identified when using this website, then you can be assured
              that it will only be used in accordance with this privacy
              statement. Mahaadeva may change this policy from time to time by
              updating this page. You should check this page from time to time
              to ensure that you are happy with any changes.
            </Subtitles>
            <Title font="19px">What information we collect.</Title>
            <Subtitles>
              <ul>
                <li> Customer Full Name</li>

                <li> Mobile/telephone and email address </li>
                <li> Billing and Shipping addresses</li>
                <li>
                  Demographic information such as preferences and interests{" "}
                </li>
                <li>
                  Other information relevant to customer surveys and/or offers
                </li>
              </ul>
              For the exhaustive list of cookies we collect see the List of
              cookies we collect section.
            </Subtitles>{" "}
            <Title font="19px">
              What we do with the information we gather.
            </Title>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:{" "}
            <Subtitles>
              <ul>
                <li> Internal record keeping.</li>

                <li>
                  {" "}
                  We may use the information to improve our products and
                  services.{" "}
                </li>
                <li>
                  {" "}
                  We may periodically send promotional emails, sms about new
                  products, special offers or other information which we think
                  you may find interesting using the email address which you
                  have provided.
                </li>
                <li>
                  From time to time, we may also use your information to contact
                  you for market research purposes. We may contact you by email,
                  phone, fax or mail. We may use the information to customize
                  the website according to your interests.
                </li>
              </ul>
            </Subtitles>{" "}
            <Title font="19px">Security</Title>
            <Subtitles>
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorized access or disclosure, we have put in
              place suitable physical, electronic and managerial procedures to
              safeguard and secure the information we collect online.
            </Subtitles>
            <Title font="19px">How we use cookies</Title>
            <Subtitles>
              A cookie is a small file which asks permission to be placed on
              your computer's hard drive. Once you agree, the file is added and
              the cookie helps analyze web traffic or lets you know when you
              visit a particular site. Cookies allow web applications to respond
              to you as an individual. The web application can tailor its
              operations to your needs, likes, and dislikes by gathering and
              remembering information about your preferences.
              <br />
              <br />
              We use traffic log cookies to identify which pages are being used.
              This helps us analyze data about web page traffic and improve our
              website in order to tailor it to customer needs. We only use this
              information for statistical analysis purposes and then the data is
              removed from the system. Overall, cookies help us provide you with
              a better website, by enabling us to monitor which pages you find
              useful and which you do not. A cookie in no way gives us access to
              your computer or any information about you, other than the data
              you choose to share with us. You can choose to accept or decline
              cookies. Most web browsers automatically accept cookies, but you
              can usually modify your browser setting to decline cookies if you
              prefer. This may prevent you from taking full advantage of the
              website.
            </Subtitles>
            <Title font="19px">Links to other websites</Title>
            <Subtitles>
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information which you provide whilst visiting
              such sites and such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
            </Subtitles>
            <Title font="19px">Controlling your personal information</Title>
            <Subtitles>
              You may choose to restrict the collection or use of your personal
              information in the following ways:
              <ul>
                <li>
                  {" "}
                  Whenever you are asked to fill in a form on the website, look
                  for the box that you can click to indicate that you do not
                  want the information to be used by anybody for direct
                  marketing purposes
                </li>
                <li>
                  If you have previously agreed to us using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by writing to or emailing us at{" "}
                  <p
                    className="link"
                    onClick={() => {
                      window.open(
                        "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Mahaadeva8888@gmail.com"
                      );
                    }}
                  >
                    Mahaadeva8888@gmail.com
                  </p>
                </li>
              </ul>
              We will not sell, distribute or lease your personal information to
              third parties unless we have your permission or are required by
              law to do so. We may use your personal information to send you
              promotional information about third parties which we think you may
              find interesting if you tell us that you wish this to happen. You
              may request details of personal information which we hold about
              you under the Data Protection Act 1998. A small fee will be
              payable. If you would like a copy of the information held on you
              please write to . If you believe that any information, we are
              holding on you is incorrect or incomplete, please write to or
              email us as soon as possible, at the above address. We will
              promptly correct any information found to be incorrect.
            </Subtitles>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default PrivacyPolicy;
