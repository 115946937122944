export interface GetCategoriesResponse {
  categories_id: number;
  name: string;
  status: number;
  created_at: string;
}

export interface GetSubCategoryById {
  id: any;
  name: string;
  category_name: string;
  category_id: string;
  status: number;
  created_at: string;
}

export interface GetProductListResposne {
  product_id: any;
  product_name: string;
  product_img: string;
  description: string;
  category_id: string;
  category_name: string;
  subcategory_id: string;
  subcategory_name: string;
  price: any;
  is_variety: number;
  variety_id: string;
  discount: string;
  created_at: string;
  quantity: number;
  discount_price: any;
  without_discount: any;
  vendor_id?: any;
}

export interface GetCarsoulImage {
  id: string;
  image: string;
  ti: any;
}

export interface GetQuotedProsp {
  q1: string;
  q2: string;
  q3: string;
}

export interface GetCouponsProsp {
  coupon_discount: string;
  coupon_name: string;
  created_at: string;
  description: string;
  id: string;
  apply_for: string;
  expiry_date: string;
  code: string;
}

export interface InitialState {
  isLoading: boolean;
  isProductLoading: boolean;
  getCategoriesLists: Array<GetCategoriesResponse>;
  getSubCategoryById: Array<GetSubCategoryById>;
  getProductsListsbyId: Array<GetProductListResposne>;
  getAllproduct: Array<GetProductListResposne>;
  getCarsoulImages: Array<GetCarsoulImage>;
  getQuotesList: Array<GetQuotedProsp>;
  getCouponsList: Array<GetCouponsProsp>;
  setSelectedCoupon: GetCouponsProsp | null;
}

export const initialState: InitialState = {
  isLoading: false,
  isProductLoading: false,
  getCategoriesLists: [],
  getSubCategoryById: [],
  getProductsListsbyId: [],
  getAllproduct: [],
  getCarsoulImages: [],
  getQuotesList: [],
  getCouponsList: [],
  setSelectedCoupon: null,
};
