import { ReactElement } from "react";
import { CouponContainer, CouponWrapper } from "../../subcomponents";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import Coupon from "../../../../assests/cou.png";
import {
  updateSelectedCoupon,
  updateSelectedCouponEmpty,
} from "../../../../redux/categories/action";

interface CouponProsp {
  totalAmount: number;
}

const CouponSlider = ({ totalAmount }: CouponProsp): ReactElement => {
  const {
    category: { getCouponsList, setSelectedCoupon },
  } = useSelector(
    (state: RootState) => ({
      category: state.category,
    }),
    shallowEqual
  );

  const dispatch: AppDispatch = useDispatch();

  return (
    <CouponContainer>
      {getCouponsList?.map((list, index) => {
        const disableCoupon = totalAmount >= Number(list?.apply_for);
        return (
          <CouponWrapper
            className="mt-3"
            key={index}
            disable={!disableCoupon}
            selected={setSelectedCoupon?.id === list?.id}
            onClick={() => {
              dispatch(updateSelectedCoupon(list));
              if (setSelectedCoupon?.id === list?.id) {
                dispatch(updateSelectedCouponEmpty({}));
              }
            }}
          >
            <img src={Coupon} alt="" />
            <div className="texts">
              {" "}
              <span>
                <b>{list?.coupon_discount}%</b> on above{" "}
                <b>₹{list?.apply_for}</b>
              </span>
              <br />
              <span className="code">{list?.code}</span>
            </div>
          </CouponWrapper>
        );
      })}
    </CouponContainer>
  );
};

export default CouponSlider;
