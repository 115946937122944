/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
  ColumnWrapper,
  Pagewrapper,
  RowWrapper,
  SectionContainer,
  SectionWrapper,
  Title,
  TitleWrapper,
  Tables,
} from "../../styledcomponents";
import {
  ContactHeaderWrapper,
  FormInput,
  ImageOpacityWrapper,
  TextWrapper,
} from "../subcomponents";
import { StyledButton, Movetop, Loader, Footer } from "../../../components";
import useRazorpay from "react-razorpay";
import { Values, shippingDetailsValues } from "./const";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  createItems,
  createOrderId,
  createUserOrder,
} from "../../../redux/user/api";
// import image from "../../../assests/mahaadeva.png";
import { useNavigate } from "react-router";
import { resetProduct } from "../../../redux/product/actions";
import CouponSlider from "./SlickSlider";
import { getCoupons } from "../../../redux/categories/api";

interface LoadRazor {
  orderId: any;
}

const CheckoutDetials = (): ReactElement => {
  const {
    user: { getOrderId, isLoading },
    cart: { products },
    category: { setSelectedCoupon },
  } = useSelector(
    (state: RootState) => ({
      user: state.user,
      cart: state.cart,
      category: state.category,
    }),
    shallowEqual
  );

  const [values, setValues] = useState(shippingDetailsValues as Values);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const localMessage: any = localStorage.getItem("message");
  const userDetails = JSON.parse(localMessage);

  let checkoutDeatils: Array<{
    product_id: string;
    price: number;
    product_name: string;
    quantity: number;
    final_price_quantity: number;
    price_without_discount: number;
    discount_price: number;
    vendor_id?: string;
    payment_method: string;
    user_id: string;
    name: string;
    phone_number: string;
    email: string;
    state: string;
    city: string;
    address: string;
    pin: string;
  }> = [];

  products?.forEach((s) => {
    checkoutDeatils.push({
      product_id: s?.product_id,
      product_name: s?.product_name,
      price: s?.price,
      quantity: s?.quantity,
      final_price_quantity: s?.quantity * s?.price,
      price_without_discount: Number(s?.quantity * s?.without_discount),
      discount_price: Number(s?.quantity * s?.discount_price),
      vendor_id: s?.vendor_id,
      payment_method: "CASH",
      user_id: userDetails?.[0]?.id,
      ...values,
    });
  });

  ///orders-details

  const price_of_item = Math.round(
    checkoutDeatils.reduce(
      (sum, current) => Number(sum) + Number(current?.price_without_discount),
      0
    )
  );

  const discount = Math.round(
    checkoutDeatils.reduce(
      (sum, current) => Number(sum) + Number(current?.discount_price),
      0
    )
  );

  const coupon_discount =
    Math.round(
      checkoutDeatils.reduce(
        (sum, current) => Number(sum) + Number(current?.final_price_quantity),
        0
      )
    ) -
    Math.round(
      Number(
        Math.round(
          checkoutDeatils.reduce(
            (sum, current) =>
              Number(sum) + Number(current?.final_price_quantity),
            0
          )
        )
      ) -
        (Number(
          Math.round(
            checkoutDeatils.reduce(
              (sum, current) =>
                Number(sum) + Number(current?.final_price_quantity),
              0
            )
          )
        ) *
          Number(`${setSelectedCoupon?.coupon_discount}`)) /
          100
    );

  const total_price = setSelectedCoupon?.id
    ? Math.round(
        Number(
          Math.round(
            checkoutDeatils.reduce(
              (sum, current) =>
                Number(sum) + Number(current?.final_price_quantity),
              0
            )
          )
        ) -
          (Number(
            Math.round(
              checkoutDeatils.reduce(
                (sum, current) =>
                  Number(sum) + Number(current?.final_price_quantity),
                0
              )
            )
          ) *
            Number(`${setSelectedCoupon?.coupon_discount}`)) /
            100
      )
    : Math.round(
        checkoutDeatils.reduce(
          (sum, current) => Number(sum) + Number(current?.final_price_quantity),
          0
        )
      );

  const [Razorpay] = useRazorpay();
  console.log(total_price, "total_price");
  const loadRazorPay = ({ orderId }: LoadRazor) => {
    const options = {
      key: `rzp_live_3wcUgSNyKL4SJM`,
      amount: `${Math.round(
        checkoutDeatils.reduce(
          (sum, current) => Number(sum) + Number(current?.final_price_quantity),
          0
        ) * 100
      )}`,
      currency: "INR",
      name: "MAHAADEVA",
      order_id: orderId,
      handler: (res: any) => {
        navigate("/");
        dispatch(
          createUserOrder({
            ...values,
            order_id: getOrderId?.id,
            coupon_code: setSelectedCoupon?.code,
            coupon_discount_price: coupon_discount,
            price: total_price,
            discount: discount,
            delivery_charge: "0",
            total_amount: total_price,
            payment_method: "CASH",
            user_id: userDetails?.[0]?.id,
          })
        ).then((data) => {
          if (data?.payload === "Order created") {
            dispatch(
              createItems({
                order_id: getOrderId?.id,
                products: checkoutDeatils,
              })
            ).then((data1) => {
              if (data1?.payload === "item created") {
                dispatch(resetProduct());
                window?.location?.reload();
              }
            });
          }
        });
      },
      prefill: {
        name: values?.name,
        email: values?.email,
        contact: values?.phone_number,
      },
      notes: {
        ...values,
      },
      theme: {
        color: "#dadcde",
      },
    };
    const rzp1 = new Razorpay(options);

    rzp1.open();
  };

  useEffect(() => {
    dispatch(getCoupons());
  }, [dispatch]);

  useEffect(() => {
    if (getOrderId?.id) {
      loadRazorPay({
        orderId: getOrderId?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderId]);
  Movetop();

  const canSubmit = !!values?.name || !!values?.phone_number || values?.email;

  return (
    <Pagewrapper>
      <ContactHeaderWrapper>
        <ImageOpacityWrapper />
        <TextWrapper>
          <Title
            color="#fff"
            hoverColor="#fff"
            font="3.5rem"
            lineHeight="26px"
            textAlign="center"
          >
            Checkout
          </Title>
        </TextWrapper>
      </ContactHeaderWrapper>
      <SectionContainer>
        <SectionWrapper>
          <span>Your order will be delivered within 8–11 working days.</span>
          <RowWrapper>
            <ColumnWrapper lg={6} justifyContent="start">
              <TitleWrapper textAlign="start" alignItems="start" width="100%">
                <Title font="1.5rem" fontWeight={600}>
                  <span className="borders"></span> BILLING & SHIPPING ADDRESS
                </Title>{" "}
                <div style={{ width: "95% " }}>
                  <FormInput
                    placeholder="Name"
                    value={values?.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        name: e?.target?.value,
                      });
                    }}
                  />
                  <FormInput
                    placeholder="Contact Number"
                    value={values?.phone_number}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        phone_number: e?.target?.value,
                      });
                    }}
                  />
                  <FormInput
                    placeholder="Email"
                    value={values?.email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        email: e?.target?.value,
                      });
                    }}
                  />
                  <FormInput
                    placeholder="State"
                    value={values?.state}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        state: e?.target?.value,
                      });
                    }}
                  />
                  <FormInput
                    placeholder="City"
                    value={values?.city}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        city: e?.target?.value,
                      });
                    }}
                  />
                  <FormInput
                    placeholder="Address"
                    value={values?.address}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        address: e?.target?.value,
                      });
                    }}
                  />
                  <FormInput
                    placeholder="Postal Code"
                    value={values?.pin}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...values,
                        pin: e?.target?.value,
                      });
                    }}
                  />
                </div>
              </TitleWrapper>
            </ColumnWrapper>
            <ColumnWrapper lg={6}>
              <TitleWrapper textAlign="start" alignItems="start" width="100%">
                <Title font="1.5rem" fontWeight={600}>
                  <span className="borders"></span> YOUR ORDER PRICE DETAIL
                </Title>{" "}
                <div
                  className="mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Tables border={0}>
                    <tbody>
                      <tr>
                        <td>
                          Price{" "}
                          <b>
                            ({checkoutDeatils?.length}{" "}
                            {checkoutDeatils?.length === 1 ? "Item" : "Items"})
                          </b>
                        </td>
                        <td>
                          <span>₹ {price_of_item}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>
                          <span style={{ color: "green" }}>- ₹ {discount}</span>
                        </td>
                      </tr>
                      {setSelectedCoupon?.code && (
                        <tr>
                          <td>
                            <h5>Coupon Discount </h5>
                          </td>
                          <td>₹{coupon_discount}</td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <h5>Total Amount</h5>
                        </td>
                        <td>
                          <b> ₹ {total_price}</b>
                        </td>
                      </tr>
                    </tbody>
                  </Tables>
                </div>
                <div
                  className="mt-4"
                  style={{
                    width: "100%",
                  }}
                >
                  <Title font="1.5rem" fontWeight={600}>
                    <span className="borders"></span> AVAILABLE COUPONS
                  </Title>{" "}
                  <CouponSlider
                    totalAmount={Math.round(
                      checkoutDeatils.reduce(
                        (sum, current) =>
                          Number(sum) + Number(current?.final_price_quantity),
                        0
                      )
                    )}
                  />
                </div>
              </TitleWrapper>
            </ColumnWrapper>
          </RowWrapper>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <StyledButton
              backGround="#ff6100"
              hoverBackground="black"
              onClick={() => {
                if (!canSubmit) {
                  alert("Please fill all the necessary fields");
                } else {
                  dispatch(
                    createOrderId({
                      ...values,
                      phone_number: `+91${values?.phone_number}`,
                      coupon_code: setSelectedCoupon?.code,
                      amount: total_price * 100,
                    })
                  );
                }
              }}
            >
              {isLoading ? (
                <Loader buttonLoader text={"Analyzing"} />
              ) : (
                "Place Order Now"
              )}
            </StyledButton>
            <StyledButton
              backGround="grey"
              hoverBackground="lightgrey"
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </StyledButton>
          </div>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </Pagewrapper>
  );
};

export default CheckoutDetials;
