import React from "react";
import {
  AmountWrapper,
  CardWrapper,
  CardWrapperContainer,
  NewAmount,
  OldAmount,
} from "../subcomponents";
import {
  ImageWrapper,
  Images,
  Title,
  TitleWrapper,
} from "../../styledcomponents";
import { Loader, StyledButton } from "../../../components";
import { GetProductListResposne } from "../../../redux/categories/types";
import { BASE_URL } from "../../../services";

interface ProductCardProsp {
  title: string;
  onClick: (id: string) => void;
  buttonLoader?: boolean;
  product?: GetProductListResposne;
  onProductClick?: () => void;
}

export const ProductCard = ({
  title,
  onClick,
  buttonLoader,
  product,
  onProductClick,
}: ProductCardProsp) => {
  const finalPrice =
    Number(product?.price) -
    (Number(product?.price) * Number(`${product?.discount}`)) / 100;

  return (
    <CardWrapper>
      <ImageWrapper width="auto" onClick={onProductClick}>
        <Images
          src={`${BASE_URL}${product?.product_img}`}
          borderRadius={"0"}
          alt="product_image"
        />
      </ImageWrapper>{" "}
      <CardWrapperContainer>
        <TitleWrapper margin="0">
          <Title
            onClick={onProductClick}
            font="16px"
            fontWeight={800}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {title}
          </Title>
          <AmountWrapper>
            {product?.discount === "0" ? (
              <NewAmount>Rs. {finalPrice}</NewAmount>
            ) : (
              <>
                <OldAmount>₹ {product?.price}</OldAmount>{" "}
                <NewAmount>₹ {finalPrice}</NewAmount>
              </>
            )}
          </AmountWrapper>
          <StyledButton className="mt-3" onClick={onClick}>
            {buttonLoader ? (
              <Loader buttonLoader text={"GOING TO CART"} />
            ) : (
              "ADD TO CART"
            )}{" "}
          </StyledButton>
        </TitleWrapper>
      </CardWrapperContainer>{" "}
    </CardWrapper>
  );
};
