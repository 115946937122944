import { Card, Carousel, FormControl } from "react-bootstrap";
import styled from "styled-components";

export const SlideSection = styled(Carousel)`
  .carousel .carousel-indicators button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
`;

export const CarouselImages = styled.img`
  object-fit: contain;
  width: 100px;
  height: auto;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
  flex-wrap: wrap;
`;

// ---------------------------------------------------Product-Card------------------------------------------

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  text-align: center;
`;

export const CardWrapper = styled(Card)`
  width: 350px;
  height: 450px;
  padding: 10px;
  margin: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  border: none;
  border-radius: 0;
  transition: all 0.5s ease;
  &:hover {
    transition: all 0.5s ease;
    background: white;
    border: none;
    img {
      transform: scale(1.1);
      transition: 2s;
    }
  }
`;

export const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 19px;
  padding: 10px 0;
  margin: 0 auto;
  cursor: pointer;
  width: 150px;
  border-bottom: 1px solid grey;
`;

export const CardWrapperContainer = styled.div`
  padding: 15px;
  max-height: 150px !important;
  height: 150px !important;
  min-height: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CardTextWrapper = styled.div``;

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OldAmount = styled.del`
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 1000;
`;
export const NewAmount = styled.span`
  color: #34a21f;
  font-size: 16px;
  font-weight: bolder;
  padding-left: 5px;
`;

//------------------------------------------

export const TextWrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
`;

export const ContactHeaderWrapper = styled.div`
  background-position: 50%;
  background-size: cover;
  height: 100px;
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const ImageOpacityWrapper = styled.div`
  position: absolute;
  background: #616161;
  width: 100%;
  height: 100%;
  opacity: 0.52;
`;

export const FormInput = styled(FormControl)`
  background: #f5f5f5;
  margin: 20px 0;
  border-radius: 6px;
  height: 48px;
  &::placeholder {
    color: #1a1668;
    font-size: 15px;
    font-weight: 400;
  }
  input::placeholder:after {
    color: red;
    content: " *";
  }
  .form-control:focus {
    box-shadow: none;
  }
`;

export const CouponContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-height: 250px;
  overflow: auto;
`;

export const CouponWrapper = styled.div<{
  disable?: boolean;
  selected?: boolean;
}>`
  width: 270px;
  max-width: 270px;
  padding: 12px;
  position: relative;
  background: ${({ disable }) =>
    disable ? "lightgray" : "rgb(255, 255, 255)"};
  border-radius: 20px;
  border: ${({ selected }) =>
    selected ? "2px solid green" : "1px solid rgb(226, 226, 231)"};
  display: inline-flex;
  margin-right: 5px;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  pointer-events: ${({ disable }) => (disable ? "none" : null)};
  cursor: pointer;
  img {
    width: 55px;
    aspect-ratio: auto 55/55;
    object-fit: contain;
  }
  span {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.3px;
    width: 100%;
    color: rgba(2, 6, 12, 0.92);
    overflow: hidden;
  }
  .code {
    font-size: 15px;
  }
  .texts {
    position: relative;
    right: 10px;
  }
`;
