import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import FooterImage from "../../assests/fotter.jpeg";

export const FooterContainer = styled.section`
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background-image: url(${FooterImage});
  background-position: center 20%;
  background-size: cover;
  width: 100%;
  z-index: 1;
  @media (max-width: 450px) {
    background-position: 85%;
    background-size: cover;
    width: 100%;
    z-index: 1;
  }
`;

export const FooterWrapper = styled(Row)`
  width: auto;
  padding: 2% 30px;
  position: relative;
  z-index: 111;
`;

export const FooterColumn = styled(Col)`
  display: flex;
  margin: 0 auto;
  justify-content: start;
`;

export const FooterAnchortext = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding: 5px 0;
  text-decoration: none;
  cursor: pointer;
  line-height: 26px;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    color: #ff6100;
  }
  i {
    font-size: 18px;
    color: #fff;
    padding-right: 5px;
    position: relative;
    top: 7px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    align-items: center;
    text-align: center;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: start;
  .svg-inline--fa {
    font-size: 13px;
    padding-right: 5px;
    color: #ff6100;
  }
`;

export const FooterBottom = styled.footer`
  border-top: 1px solid #f3f3f3;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 28px 0 0;
`;

export const FooterBottomContainer = styled.footer`
  display: flex;
  width: 100%;
  padding: 0 28px;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const ImageOpacityWrapper = styled.div`
  position: absolute;
  background: #333333;
  width: 100%;
  height: 100%;
  opacity: 0.8;
`;
