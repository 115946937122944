import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

export interface ToastProsp {
  show: boolean;
  handleClose: any;
  severity: AlertColor;
  message: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastMessage = ({ show, severity, message, handleClose }: ToastProsp) => {
  return (
    <Stack
      spacing={2}
      sx={{ width: "100%", position: "relative", zIndex: 1111 }}
    >
      <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          <b>{message}</b>
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ToastMessage;
