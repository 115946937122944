import React, { ReactElement } from "react";
import {
  Pagewrapper,
  SectionContainer,
  SectionWrapper,
  Title,
  Tables,
  TableHeader,
} from "../../styledcomponents";
import {
  ContactHeaderWrapper,
  ImageOpacityWrapper,
  TextWrapper,
} from "../subcomponents";
import { Movetop, StyledButton } from "../../../components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ROUTES from "../../../const/routes";
import { BASE_URL } from "../../../services";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  CountValue,
  CounterWrapper,
  DecreamentButton,
  IncreamentButton,
} from "../../../components/Cardcounter/subcomponents";
import {
  deleteIteminCart,
  drecreaseQuantity,
  increaseQuantity,
} from "../../../redux/product/actions";

const CartList = (): ReactElement => {
  const {
    cart: { products },
  } = useSelector(
    (state: RootState) => ({
      cart: state.cart,
    }),
    shallowEqual
  );

  let navigate = useNavigate();
  const dispatch = useDispatch();
  Movetop();

  return (
    <Pagewrapper>
      <ContactHeaderWrapper>
        <ImageOpacityWrapper />
        <TextWrapper>
          <Title
            color="#fff"
            hoverColor="#fff"
            font="3.5rem"
            lineHeight="26px"
            textAlign="center"
          >
            Cart
          </Title>
        </TextWrapper>
      </ContactHeaderWrapper>
      <SectionContainer background="#ffff" padding="40px 0">
        <SectionWrapper>
          {products?.length === 0 ? (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <StyledButton
                onClick={() => {
                  navigate("/");
                }}
              >
                Explore our Products
              </StyledButton>
            </div>
          ) : (
            <>
              <Tables responsive>
                <TableHeader>
                  <tr>
                    <th>Product</th>
                    <th style={{ width: "300px" }}>Product Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Remove from Cart</th>
                  </tr>
                </TableHeader>
                <tbody>
                  {products?.map((product: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={`${BASE_URL}${product?.product_img}`}
                            alt=""
                            width={"100px"}
                            height={"100px"}
                            style={{ objectFit: "contain" }}
                          />
                        </td>
                        <td style={{ width: "300px" }}>
                          {product?.product_name}
                        </td>
                        <td>{`₹ ${product?.price}`}</td>
                        <td>
                          <CounterWrapper>
                            <div
                              style={{
                                background: "lightgray",
                                borderRadius: "10px",
                              }}
                            >
                              <DecreamentButton
                                disabled={product?.quantity === 1}
                                onClick={() => {
                                  dispatch(
                                    drecreaseQuantity({
                                      id: product?.product_id,
                                    })
                                  );
                                }}
                              >
                                -
                              </DecreamentButton>
                              <CountValue>{product?.quantity}</CountValue>
                              <IncreamentButton
                                onClick={() => {
                                  dispatch(
                                    increaseQuantity({
                                      id: product?.product_id,
                                    })
                                  );
                                }}
                              >
                                +
                              </IncreamentButton>
                            </div>
                          </CounterWrapper>
                        </td>
                        <td>{`₹ ${product?.quantity * product?.price}`}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={["fas", "trash-alt"]}
                            style={{ cursor: "pointer" }}
                            title="Delete Product"
                            onClick={() => {
                              dispatch(
                                deleteIteminCart({
                                  id: product?.product_id,
                                })
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Tables>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {" "}
                <StyledButton
                  onClick={() => {
                    navigate(ROUTES.CHECKOUT_DETAILS);
                  }}
                >
                  Proceed to checkout
                </StyledButton>
                <StyledButton
                  backGround="grey"
                  hoverBackground="lightgrey"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Back
                </StyledButton>
              </div>
            </>
          )}
        </SectionWrapper>
      </SectionContainer>
    </Pagewrapper>
  );
};

export default CartList;
